<template>
  <div class="header py-4">
    <div class="container">
      <div class="d-flex justify-content-between">
        <template v-if="profile">
          <div class="nav-item d-flex p-0 hidden-xs">
            <router-link to="/create-lead" class="btn btn-sm btn-success mr-2" v-if="profile.referralCode">Add lead</router-link>
            <router-link to="/dashboard" class="btn btn-sm btn-outline-primary mr-2">Dashboard</router-link>
            <a href="https://janover.notion.site/c7e3d43b607040c3af16fe939d8f04e2?v=d5dba892f05c4a02afd58bd7eb016c78" target="_blank" class="btn btn-sm btn-outline-primary mr-2">Resources</a>
            <router-link to="/faq" class="btn btn-sm btn-outline-primary mr-2">FAQ</router-link>
            <router-link to="/contact" class="btn btn-sm btn-info"><i class="fe fe-message-circle mr-2"></i>Get help</router-link>
          </div>

          <div class="visible-xs">
            <a href="#" class="header-toggler" @click.prevent="navIsToggled = !navIsToggled">
              <span class="header-toggler-icon"></span>
            </a>
          </div>

          <div class="mobile-nav visible-xs" v-show="navIsToggled">
            <router-link to="/dashboard" class="mb-3">Dashboard</router-link>
            <router-link to="/resources" class="mb-3">Resources</router-link>
            <router-link to="/faq" class="mb-3">FAQ</router-link>
            <router-link to="/create-lead" class="btn btn-sm btn-success mb-3" v-if="profile.referralCode">Add lead</router-link>
            <router-link to="/contact" class="btn btn-sm btn-info"><i class="fe fe-message-circle mr-2"></i>Get help</router-link>
          </div>

          <router-link to="/profile" class="nav-link pr-0 leading-none">
            <span class="avatar avatar-azure" v-if="!profile.image">{{ avatarPlaceholder }}</span>
            <span class="avatar" v-else :style="{ 'background-image': `url(${profile.image})`}"></span>
            
            <span class="ml-2 d-none d-lg-block">
              <span class="text-default">{{ profile.firstName }} {{ profile.lastName }}</span>
              <small class="text-muted d-block mt-1">{{ profile.company }}</small>
            </span>
          </router-link>
        </template>

        <template v-else>
          <div class="nav-item d-none d-md-flex p-0">
            <router-link to="/login" class="btn btn-sm btn-primary">Login</router-link>
            <router-link to="/sign-up" class="btn btn-sm btn-outline-primary ml-2">Sign Up</router-link>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  
  export default {
    name: 'TheTopbar',

    data () {
      return {
        navIsToggled: false
      }
    },

    computed: {
      profile () {
        return this.$store.getters.profile
      },

      avatarPlaceholder () {
        let placeholder = ''

        if (this.profile.firstName) placeholder += this.profile.firstName.split('')[0]
        if (this.profile.lastName) placeholder += this.profile.lastName.split('')[0]

        return placeholder
      }
    }
  }

</script>

<style lang="scss" scoped>
  /deep/ .btn i {
    font-size: 0.9rem;
    margin-right: 0.4rem !important;
  }

  @media screen and (max-width: 767px) {
    .hidden-xs {
      display: none !important;
    }

    .header {
      position: relative;
    }
  }

  @media screen and (min-width: 768px) {
    .visible-xs {
      display: none !important;
    }
  }

  .header-toggler {
    display: block;
  }

  .mobile-nav {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    z-index: 99;
    padding: 24px 20px 30px !important;
    margin-top: 1px;
    flex-direction: column;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: collumn;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 40, 100, 0.12);
  }
</style>
