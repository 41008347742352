<template>
  <div class="profile-details-wrapper">
  	<form class="card" novalidate @submit.prevent="handleSubmit">
      <div class="card-body">
        <h3 class="card-title">Edit Profile</h3>

        <div class="alert alert-danger" role="alert" v-if="alert">
          {{ alert }}
        </div>
        
        <div class="row">
          <div class="col-sm-6 col-md-6">
            <form-field 
              id="firstName"
              placeholder="First Name"
              label="First Name"
              type="text"
              :disabled="profile.firstName.length > 0"
              v-model="formFields.firstName"
            />
          </div>

          <div class="col-sm-6 col-md-6">
            <form-field 
              id="lastName"
              placeholder="Last Name"
              label="Last Name"
              type="text"
              :disabled="profile.lastName.length > 0"
              v-model="formFields.lastName"
            />
          </div>

          <div class="col-md-6">
            <form-field 
              id="email"
              placeholder="Email"
              label="Email Address"
              type="email"
              disabled=""
              v-model="profile.email"
            />
          </div>

          <div class="col-md-6">
            <form-field 
              id="company"
              placeholder="Company"
              label="Company"
              type="text"
              v-model="formFields.company"
            />
          </div>

          <div class="col-sm-6 col-md-6">
            <form-field 
              id="phoneNumber"
              placeholder="Phone Number"
              label="Phone Number"
              type="text"
              mask="(999) 999-9999"
              v-model="formFields.phoneNumber"
            />
          </div>

          <div class="col-sm-6 col-md-6">
            <form-field 
              id="bio"
              placeholder="Short description"
              label="Bio"
              type="text"
              v-model="formFields.bio"
            />
          </div>
        </div>
      </div>
      
      <div class="card-footer text-right">
        <button type="submit" class="btn btn-primary" :disabled="isLoading">Update Profile</button>
      </div>
    </form>
  </div>
</template>

<script>

  import FormField from '@/components/FormField'

	export default {
		name: 'ProfileDetailsUpdate',

    components: {
      FormField
    },

		data () {
			return {
        bio: 'test',

        formFields: {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          bio: '',
          company: ''
        },

        isLoading: false,
        alert: false
			}
		},

		computed: {
			profile () {
				return this.$store.getters.profile
			}
		},

    methods: {
      init () {
        for (const key in this.formFields) {
          this.formFields[key] = this.profile[key]
        }
      },

      async handleSubmit () {
        this.isLoading = true

        try {
          let response = await this.$store.dispatch('updateProfile', this.formFields)

          this.$store.dispatch('notify', {
            message: 'Your profile details have been updated successfully',
            type: 'success',
            timer: 3000
          })

          this.isLoading = false
        } catch(e) {
          this.alert = e.error || e
          this.isLoading = false
        }
      }
    },

    created () {
      this.init()
    }
	}
</script>