<template>
  <div class="card">
    <div class="card-body">
      <h3 class="card-title">Revenue Share</h3>

      <div class="row mb-2">
        <div class="col-md-4">
          <div class="form-group">
            <div class="input-group">
              <input type="text" class="form-control" aria-label="Percents" :value="profile.revenueShare * 100" disabled="">
              <span class="input-group-append">
                <span class="input-group-text">%</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <h3 class="card-title">Payment Schedule (in days)</h3>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group mb-1">
            <div class="input-group" style="max-width: 80px">
              <input type="text" class="form-control" aria-label="Days" value="30" disabled="">
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
  
  export default {
    computed: {
      profile () {
        return this.$store.getters.profile
      }
    }
  }

</script>