export default {
	email: {
		type: 'email',
		placeholder: 'Email',
		label: 'Email address',
		required: true,
		validation: [
			'isEmail'
		],
		value: ''
	}
}