<template>
	<footer class="footer">
    <div class="container">
      <div class="row align-items-center flex-row justify-space-between">
        <div class="col-md-6">
          <div class="row align-items-center mobile-center">
            <div class="col-auto">
              <ul class="list-inline list-inline-dots mb-0">
                <li class="list-inline-item"><router-link to="/terms-and-conditions">Terms & Conditions</router-link></li>
                <li class="list-inline-item"><router-link to="/privacy-policy">Privacy Policy</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-right mobile-center">
          © {{currentYear}} Janover Ventures.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  
  export default {
    name: 'TheFooter',

    computed: {
    	currentYear () {
    		return new Date().getFullYear()
    	}
    }
  }

</script>

<style lang="scss" scoped>
  @media (max-width: 767px) {
    .mobile-center {
      text-align: center !important;
      justify-content: center;

      &.text-right {

      }
    }
  }
</style>