<template>
  <page-simple v-if="routeIsReady">
    <div class="container my-6">
      <div class="row">
        <div class="col-12 col-lg-8 mx-auto">
          <notifications-feed :notifications="notifications" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-8 mx-auto">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Support Center</h3>
            </div>
            <div class="support-form">
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-simple>
</template>



<script>
  
  import PageSimple from '@/components/PageSimple'
  import NotificationsFeed from '@/components/NotificationsFeed'

  import route from '@/mixins/route'

  export default {
    name: 'Contact',

    mixins: [
      route
    ],

    components: {
      PageSimple,
      NotificationsFeed
    },

    data () {
      return {
        message: '',
        subject: 'Partner platform support request',
        isLoading: false,
        alert: '',
        notifications: []
      }
    },

    computed: {
			profile () {
				return this.$store.getters.profile
			}
		},

    

    mounted ()  {
      if (window && !window.hbspt) {
				const script = document.createElement('script')
				script.src = "https://js.hsforms.net/forms/v2.js"
				document.querySelector('.support-form').appendChild(script)
			}
			setTimeout(() => {
				hbspt.forms.create({
					portalId: "8599603",
					formId: "0ecf61dd-5c71-44b9-80ca-4ecf368d2679",
					onFormReady:  function ($form){
						console.log($form);
						var partnerEl = $form[0].querySelector('input[name="email"]');
						partnerEl.value = this.profile.email;
						partnerEl.dispatchEvent(new Event('change'));
					},
					target: '.support-form'
				});
			}, 1000);
    },

    methods: {
      async handleFormSubmit () {
        if (!this.isLoading) {
          this.alert = ''
          this.isLoading = true

          try {
            await this.$store.dispatch('contactSupport', {
              subject: this.subject,
              message: this.message,
              from: this.profile.email
            })

            this.subject = 'Partner platform support request',
            this.message = ''
            this.isLoading = false
            
            this.notifications.push({
              message: 'Your request has been sent.',
              timer: 5000,
              type: 'success'
            })
          } catch(e) {
            console.log(e)
            this.notifications.push({
              message: e.error || e,
              timer: 5000,
              type: 'danger'
            })
            this.isLoading = false
          }
        }
      }
    }
  }

</script>



<style scoped>
  .support-form {
    padding: 20px;
  }
</style>
