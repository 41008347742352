<template>
	<div class="page-main">
    <the-topbar />

    <slot />

    <the-footer />
  </div>
</template>

<script>
	
	import TheTopbar from '@/components/TheTopbar'
  import TheFooter from '@/components/TheFooter'

	export default {
		name: 'PageSimple',

		components: {
			TheFooter,
			TheTopbar
		},

    mounted () {
      window.scrollTo(0,0)
    }
	}

</script>

<style lang="scss" scoped>

	.page-main {
    padding-bottom: 62px;
    position: relative;

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

</style>
