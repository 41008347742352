<template>
  <div class="card" :class="{ 'is-loading': !isLoaded }">
    <div class="card-header d-flex align-items-center justify-content-between">
      <h3 class="card-title">{{ label }}</h3>
      <i class="fe" :class="parseIcon(icon)"></i>
    </div>

    <div class="card-body loader-wrap d-flex justify-content-center align-items-center" v-if="!isLoaded">
      <div class="loader"></div>
    </div>

    <div class="card-body loader-wrap d-flex justify-content-center align-items-center" v-if="isLoaded && data.length < 1">
      There are no {{ label.toLowerCase() }} yet.
    </div>

    <div class="table-responsive" v-else>
      <table class="table card-table table-striped table-vcenter">
        <template v-if="type == 'duplicates'">
          <thead>
            <tr>
              <th>Email</th>
              <th>Phone</th>
              <th>Date</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in pageData">
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ getDateString(item.created) }}</td>
            </tr>
          </tbody>
        </template>

        <template v-else-if="type !== 'deals'">
          <thead>
            <tr>
              <th colspan="2">Name</th>
              <th>Date created</th>
              <th>Last updated</th>
              <th class="text-center">Deals</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in pageData">
              <td><b><span>{{ item.firstname || '–' }}</span> <span>{{ item.lastname }}</span></b></td>
              <td></td>
              <td>{{ getDateString(item.createdate) }}</td>
              <td>{{ getDateString(item.notes_last_updated || item.createdate) }}</td>
              <td class="text-center">{{ item.num_associated_deals }}</td>
            </tr>
          </tbody>
        </template>

        <template v-else>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status updated</th>
              <th>Loan reason</th>
              <th>Est. loan amount</th>
              <th>Est. revenue</th>
              <th>Stage</th>
              <!-- <th>Last updated</th> -->
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in pageData">
              <td><b>{{ item.dealname }}</b></td>
              <td>{{ getDateString(item.createdate) }}</td>
              <td>{{ item.loan_purpose || item.loan_purpose_sba || '–' }}</td>
              <td>{{ formatNumber(item.estimated_loan_amount) }}</td>
              <td>{{ formatNumber(item.amount * profile.revenueShare) }}</td>
              <td>
                {{ item.dealstage }}
              </td>
              <!-- <td>{{ getDateString(item.createdate) }}</td> -->
            </tr>
          </tbody>
        </template>
      </table>
    </div>

    <div class="card-footer d-flex align-items-center justify-content-between" v-if="isLoaded && data.length > 0">
      <div class="" role="status" aria-live="polite">Showing {{ activePage + 1 }} to {{ (activePage + 1) * pageSize }} of {{ data.length }} entries</div>
      <pagination 
        class="pagination"
        container-class="pagination"
        page-class="pagination__page"
        page-link-class="pagination__button"
        prev-class="pagination__page"
        prev-link-class="pagination__button"
        next-class="pagination__page"
        next-link-class="pagination__button"
        v-if="pageCount > 1"
        :page-count="pageCount"
        :page-range="3"
        :click-handler="handlePagination"
      />
    </div>
  </div>
</template>

<script>

  import Pagination from 'vuejs-paginate'

  import formatNumber from '@/helpers/formatNumber.js'
  import parseIcon from '@/helpers/parseIcon.js'
  
  export default {
    props: {
      data: Array,
      icon: String,
      isLoaded: Boolean,
      label: String,
      type: String
    },

    computed: {
      pageCount () {
        return (this.data.length > 0) ? (Math.ceil(this.data.length / this.pageSize)) : 1
      },

      pageData () {
        const start = this.activePage * this.pageSize
        const end = start + this.pageSize

        return this.data.slice(start, end)
      },

      profile () {
        return this.$store.getters.profile
      }
    },

    data () {
      return {
        pageSize: 10,
        activePage: 0
      }
    },

    components: {
      Pagination
    },

    methods: {
      handlePagination (page) {
        this.activePage = page-1
      },

      getDateString (d) {
        const date = new Date(d).toDateString()

        return `${date.slice(4, date.length)}`
      },

      getShortDate (d) {
        const date = new Date(d)

        return `${('0'+(date.getMonth()-1)).slice(-2)}/${('0'+date.getDate()).slice(-2)}`
      },

      formatNumber (number) {
        return formatNumber(number, '$0,0')
      },

      parseIcon,

      getStatus (item) {
        if (item.custom_fields['[dev] Route'] && item.custom_fields['[dev] Route'] == 'No destination found') return 'No Lender Match'
        return item.status
      },

      getNote (item) {
        if (item.notes && item.notes.length > 0) {
          let note = item.notes[item.notes.length - 1]
          let content = note.content.replace('RP: ', '')

          if (note.updated_at) return `${this.getShortDate(note.updated_at)}: ${content}`
          return content
        }
        return '–'
      }
    }
  }

</script>

<style lang="scss" scoped>
  .is-loading {
    opacity: 0.6;
  }

  .loader-wrap {
    min-height: 265px;
  }

  td p {
    max-width: 240px;
  }
  b > span {
    text-transform: capitalize;
  }

  .pagination /deep/ {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .pagination__button {
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
      display: block;
      font-weight: 400;
      color: #495057;
      text-align: center;
      user-select: none;
      background-color: transparent;
      border: 1px solid rgba(0, 40, 100, 0.12);
      padding: 0.375rem 0.75rem;
      font-size: 0.9375rem;
      border-radius: 4px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      margin: 0 0.2rem;
      outline: none;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        text-decoration: none;
        background-color: #f6f6f6;
      }
    }

    .pagination__page {
      &.active {
        .pagination__button {
          color: #fff;
          background-color: #467fcf;
          border-color: #467fcf;
        }
      }

      &.disabled {
        .pagination__button {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
  }
</style>
