<template>
  <div class="form-group">
    <label class="form-label" for="password">{{ label }}</label>
    <multiselect
      v-model="localState"
      :options="options"
      :multiple="true"
      :class="{
        'has-data-selected': localState.length > 0,
        'is-invalid': invalid
      }"
    />
  </div>
</template>

<script>

  import Multiselect from 'vue-multiselect'
  
  export default {
    name: 'FormMultiselect',

    components: {
      Multiselect
    },

    props: {
      id: String,
      value: Array,
      label: String,
      options: Array,
      invalid: Boolean
    },

    data () {
      return {
        localState: []
      }
    },

    watch: {
      localState () {
        this.$emit('input', this.localState)
      }
    }
  }

</script>

<style lang="scss" scoped>
  .form-check {
    cursor: pointer;
  }

  /deep/ .multiselect {
    &__input,
    &__single {
      font-size: 14px;
      font-family: system-ui;
    }

    &__input {
      margin-bottom: 0;
      padding: 1px 0 0 3px;
    }

    &__tags {
      border: 1px solid rgba(0, 40, 100, 0.12);
      border-radius: 3px;
    }

    .multiselect__option--selected:after {
      display: none;
    }

    &.multiselect--active {
      .multiselect__tags {
        border-color: #1991eb;
        box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
      }
    }

    &__content-wrapper {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &__option {
      font-size: 14px;

      &--highlight {
        background-color: var(--azure);

        &:after {
          background-color: inherit;
          display: none;
        }
      }
    }

    &__placeholder {
      padding-left: 3px;
      font-size: 14px;
      font-family: system-ui;
      margin: 0;
      padding: 0 0 0 3px;
      color: #b0b6be;
    }

    &__tag {
      font-size: 0.75rem;
      color: #6e7687;
      background-color: #e9ecef;
      border-radius: 3px;
      padding: 0 1.5rem 0 .5rem;
      line-height: 22px;
      display: inline-flex;
      font-weight: 400;
      user-select: none;

      &:last-child {
        margin-bottom: 8px;
      }

      span {
        display: inline-block;
        margin-right: 5px;
      }

      &-icon {
        margin: 0;
        background-color: rgba(0, 0, 0, 0.06);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        &:after {
          color: inherit;
        }
      }
    }

    &.has-data-selected {
      .multiselect__input {
        display: none !important;
      }
    }

    &.is-invalid {
      .multiselect__tags {
        border-color: var(--danger);
      }
    }
  }
</style>