<template>
  <page-simple v-if="routeIsReady">
		<div class="my-3 my-md-5">
      <div class="container">
        <div class="tabs mb-5" v-if="profile.commissionFrom && profile.commissionFrom.length > 0">
          <button
            class="btn btn-square btn-secondary" 
            :class="{ 'is-active': mainTab }"
            @click="mainTab = true"
          >Your activity</button>
          
          <button
            class="btn btn-square btn-secondary ml-2" 
            :class="{ 'is-active': !mainTab }"
            @click="mainTab = false"
            v-if="profile.commissionFrom && profile.commissionFrom.length > 0"
          >Activity of invited referrals</button>
        </div>

        <template v-if="mainTab">
          <div class="row">
            <div class="col-12">
              <referral-link :minimized="true" />
            </div>

            <div class="col-sm-6 col-lg-4">
              <data-preview
                :data="contactsPreview"
                :isLoaded="contactsLoaded"
                :isActive="activeTab == 'contacts'"
                icon="contacts"
                @click.native="setActiveTab('contacts')"
                class="pointer"
              />
            </div>

            <div class="col-sm-6 col-lg-4">
              <data-preview
                :data="dealsPreview"
                :isLoaded="dealsLoaded"
                :isActive="activeTab == 'deals'"
                icon="deals"
                @click.native="setActiveTab('deals')"
                class="pointer"
              />
            </div>

            <div class="col-sm-6 col-lg-4">
              <data-preview
                :data="total"
                :isLoaded="dealsLoaded"
                :isActive="activeTab == 'closedDeals'"
                icon="total"
                @click.native="setActiveTab('closedDeals')"
                class="pointer"
              />
            </div>

            <div class="col-sm-6 col-lg-4">
              <data-preview
                :data="viewsPreview"
                :isLoaded="!!profile"
                icon="views"
              />
            </div>

            <div class="col-sm-6 col-lg-4">
              <data-preview
                :data="duplicatesPreview"
                :isLoaded="duplicatesLoaded"
                :isActive="activeTab == 'duplicates'"
                icon="duplicates"
                @click.native="setActiveTab('duplicates')"
                class="pointer"
              />
            </div>

            <div class="col-sm-6 col-lg-4">
              <data-preview
                :data="pipline"
                :isLoaded="dealsLoaded"
                icon="total"
              />
            </div>

            

            <div class="col-12">

              <data-table 
                v-if="activeTab == 'duplicates'" 
                :data="duplicates" 
                :isLoaded="duplicatesLoaded" 
                icon="duplicates" 
                key="duplicates"
                type="duplicates"
                label="Duplicates"
              />
              
              <data-table 
                v-if="activeTab == 'contacts'" 
                :data="contacts" 
                :isLoaded="contactsLoaded" 
                icon="contacts"
                key="contacts"
                label="Contacts"
              />
              
              <data-table 
                v-if="activeTab == 'deals'" 
                :data="deals" 
                :isLoaded="dealsLoaded" 
                type="deals"
                icon="deals"
                label="Deals"
                key="deals"
              />
              
              <data-table 
                v-if="activeTab == 'closedDeals'" 
                :data="closedDeals" 
                :isLoaded="dealsLoaded" 
                type="deals" 
                icon="total"
                key="closedDeals"
                label="Closed deals"
              />
            </div>
          </div>
        </template>
        
        <template v-else>
          <invited-activity />
        </template>
      </div>
    </div>
  </page-simple>
</template>



<script>


  import PageSimple from '@/components/PageSimple'

  import ReferralLink   from '@/components/ProfileReferralLink'
  import Invitation   from '@/components/ProfileInvitation'
  import DataPreview from '@/components/DataPreview'
  import DataTable from '@/components/DataTable'
  import ConnectionsChart from '@/components/ConnectionsChart'
  import InvitedActivity from '@/components/DashboardInvitedActivity'
  import Notification from '@/components/Notification'

  import { mapActions, mapGetters } from 'vuex'

  import formatNumber from '@/helpers/formatNumber.js'
  import getTodays from '@/helpers/getTodays.js'
  
  import route from '@/mixins/route.js'
	
	export default {
		name: 'Dashboard',

    mixins: [
      route
    ],

    computed: {
      ...mapGetters([
        'contacts',
        'deals',
        'duplicates',
        'profile'
      ]),

      revenueShare () {
        return this.profile.revenueShare
      },

      total () {
        let dealsTotal = 0

        if (this.closedDeals) {
          this.closedDeals.forEach(deal => {
            dealsTotal += deal.amount
          })
        }

        return {
          title: `${formatNumber(dealsTotal * this.revenueShare)}`,
          subtitle: `earned`
        }
      },

      contactsDataFromDeals () {
        return (this.deals) ? this.deals.map(deal => {
          return deal.contact_id
        }) : []
      },

      contactsNotDeals () {
        return this.contacts.filter(contact => {
          return !this.contactsDataFromDeals.includes(contact.id)
        })
      },

      contactsPreview () {
        return {
          title: `${this.contacts.length} <small>Contacts</small>`,
          subtitle: `${getTodays(this.contacts)} today`,
          tooltip: 'A <b>contact</b> is the individual person that you have referred. You can also think of a contact as a "lead"'
        }
      },

      dealsPreview () {
        return {
          title: `${this.deals.length} <small>Deals</small>`,
          subtitle: `${getTodays(this.deals)} today`,
          tooltip: 'A <b>deal</b> is created (and associated with a contact) for each loan inquiry a contact submits. It is possible for 1 contact to have more than 1 deal associated with them.'
        }
      },

      duplicatesPreview () {
        return {
          title: `${this.duplicates.length} <small>Duplicates</small>`,
          subtitle: `${getTodays(this.duplicates)} today`,
          tooltip: 'This lead does not qualify because already exists in our database. This is a <b>duplicate</b>.'
        }
      },

      viewsPreview () {
        return {
          title: `${this.profile.views} views`,
          subtitle: 'of your link'
        }
      },

      totalRequestsPreview () {
        return {
          title: `${this.contacts.length + this.duplicates.length} actions`,
          subtitle: `${getTodays(this.contacts) + getTodays(this.duplicates)} actions today`
        }
      },

      closedDeals () {
        return this.deals.filter(deal => {
          return deal.dealstage === 'Closed: Won'
        })
      },

      pipline () {
        let piplineTotal = 0

        if (this.deals && this.deals.length > 0) {
          this.deals.forEach(deal => {
            if (["Sourcing Terms", "Terms Received", "LOI Sent", "LOI Executed"].includes(deal.dealstage)) {
              piplineTotal += deal.amount * this.revenueShare
              // console.log(deal);
            }
          })
        }

        return {
          title: `${formatNumber(piplineTotal)}`,
          subtitle: 'in pipeline'
        }
      }
    },

    components: {
      PageSimple,
      DataPreview,
      DataTable,
      ConnectionsChart,
      ReferralLink,
      Invitation,
      InvitedActivity,
      Notification
    },

    data () {
      return {
        contactsLoaded: false,
        dealsLoaded: false,
        duplicatesLoaded: false,
        alert: null,
        activeTab: 'contacts',
        mainTab: true,
        commission: null
      }
    },

    methods: {
      ...mapActions([
        'getContacts',
        'getDeals',
        'getDuplicates'
      ]),

      async initDashboard () {
        try {
          this.duplicatesLoaded = this.duplicates ? true : await this.getDuplicates()
          this.contactsLoaded = this.contacts.length > 0 ? true : await this.getContacts()
          this.dealsLoaded = this.deals.length > 0 ? true : await this.getDeals()
        } catch(e) {
          this.alert = e.error || e
        }
      },

      setActiveTab (tab) {
        this.activeTab = tab
      }
    },

    mounted () {
      if (this.routeIsReady) this.initDashboard()
    }
	}

</script>



<style lang="scss" scoped>
	
	.input-group-flat {
		span {
			border-right: none;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

  @media (max-width: 991px) {
    .hidden-sm {
      display: none !important;
    }
  }

  .btn.is-active {
    box-shadow: 0 0 0 1px var(--azure);
  }

</style>
