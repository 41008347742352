<template>
	<div :class="className" role="alert" v-if="alive">
    {{ message }}
  </div>
</template>

<script>
	
	export default {
		name: 'Notification',

		props: {
			message: String,
			type: String,
			timer: Number,
			created: Date
		},

		data () {
			return {
				alive: true
			}
		},

		computed: {
			className () {
				return `alert alert-${this.type}`
			}
		},

		created () {
			if (this.timer) {
				setTimeout(() => {
					this.alive = false
				}, this.timer)
			}
		}
	}

</script>