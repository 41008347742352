import isToday from './isToday.js'

export default function (array) {
  if (array.length < 1) return 0

  return array.reduce((counter, item) => {
    let date = item.created_date || item.created
    if (isToday(item.created_date)) counter++
    return counter
  }, 0)
}