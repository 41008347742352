export default {
	set (name, value, expires = 2) {
		const date = new Date();
	  date.setTime(date.getTime() + expires * 24 * 7 * 60 * 60 * 1000);
	  document.cookie = `${name}=${value};${'expires=' + date.toUTCString()}`;
	},

	get (name) {
		var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
	  if (match) return match.pop();
	}
}