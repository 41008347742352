<template>
  <div class="card" :class="{ 'is-loading': !isLoaded }">
    <div class="card-header" v-if="label">
      <h3 class="card-title">{{ label }}</h3>
    </div>
    <div class="card-body">
      <chart v-if="isLoaded" :data="data" />
      <div class="loader" v-else></div>
    </div>
  </div>
</template>

<script>

  import c3 from 'c3'
  import chart from './chart.vue'
  
  export default {
    props: {
      data: Object,
      label: String,
      isLoaded: Boolean
    },

    data () {
      return {
        chart: null
      }
    },

    components: {
      chart
    }
  } 

</script>

<style lang="scss" scoped>
  /deep/ .card-body {
    padding-left: 1rem;
    height: 368px;
  }


  .card.is-loading {
    opacity: 0.6;
    position: relative;

    /deep/ .loader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
</style>