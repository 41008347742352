<template>
  <div
    class="card p-3"
    :class="{
      'is-loading': !isLoaded,
      'is-active': isActive
    }"
  >
    <div class="custom-tooltip" v-if="data.tooltip">
      <i class="fe fe-info"></i>
      <p v-html="data.tooltip"></p>
    </div>
    <div class="d-flex align-items-center">
      <span class="stamp stamp-md mr-3" :class="color">
        <i class="fe" :class="parsedIcon"></i>
      </span>
      <div v-if="isLoaded">
        <h4 class="m-0" v-html="data.title"></h4>
        <small class="text-muted" v-html="data.subtitle"></small>
      </div>

      <div class="loader" v-else></div>
    </div>
  </div>
</template>

<script>

  import parseIcon from '@/helpers/parseIcon'
  
  export default {
    props: {
      data: Object,
      icon: String,
      isLoaded: {
        type: Boolean,
        default: true
      },
      isActive: Boolean
    },

    computed: {
      parsedIcon () {
        return parseIcon(this.icon)
      },

      color () {
        switch (this.icon) {
          case 'duplicates':
            return 'bg-red'
          case 'leads':
            return 'bg-yellow'
          case 'contacts':
            return 'bg-blue'
          case 'deals':
            return 'bg-info'
          case 'total':
            return 'bg-green'
          default:
            return ''
        }
      }
    }
  }

</script>

<style lang="scss" scoped>
  .pointer {
    cursor: pointer;
  }

  /deep/ .card {
    min-height: 67px;
    position: relative;
  }

  .custom-tooltip {
    color: var(--azure);
    position: absolute;
    text-align: center;
    width: 20px;
    height: 20px;
    top: 4px;
    right: 4px;
    z-index: 9;

    p {
      display: none;
      position: absolute;
      background: white;
      box-shadow: 0 0 2px rgba(0,0,0,0.2);
      width: 200px;
      font-size: 12px;
      color: var(--gray);
      bottom: 100%;
      margin-bottom: 8px;
      right: 10px;
      margin-right: -100px;
      padding: 10px;
      box-sizing: border-box;
      text-align: left;
      border-radius: 4px;
    }

    &:hover {
      p {
        display: block;
      }
    }
  }
  
  .is-loading {
    opacity: 0.6;
  }

  .is-active {
    box-shadow: 0 0 2px 1px var(--azure);
  }
</style>
