<template>
	<div class="my-3 my-md-5 paperform-sba-wrap" style="max-width: 680px;margin: 0 auto;"></div>
</template>

<script>
	
	import { mapGetters } from 'vuex'

	export default {
		name: 'CmForm',

		computed: {
			...mapGetters([
				'profile'
			])
		},

		mounted () {

			window.jQuery = window.jQuery || function(nodeOrSelector) {
				if (typeof(nodeOrSelector) == 'string') {
					return document.querySelector(s);
				}
				return nodeOrSelector;
			};
			window.$ = window.$ || function(nodeOrSelector) {
				if (typeof(nodeOrSelector) == 'string') {
					return document.querySelector(s);
				}
				return nodeOrSelector;
			};
		

			if ( typeof window.CustomEvent !== "function" ) {
				function CustomEvent ( event, params ) {
					params = params || { bubbles: false, cancelable: false, detail: undefined };
					var evt = document.createEvent( 'CustomEvent' );
					evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
					return evt;
				}

				CustomEvent.prototype = window.Event.prototype;

				window.CustomEvent = CustomEvent;
			}

			window.referralCode = this.profile.referralCode;

			if (window && !window.hbspt) {
				const script = document.createElement('script')
				script.src = "https://js.hsforms.net/forms/v2.js"
				document.querySelector('.paperform-sba-wrap').appendChild(script)
			}

			setTimeout(() => {
				hbspt.forms.create({
					portalId: "8599603",
					formId: "cb03dcc3-2dba-4382-9538-de4430ed3e53",
					onFormReady:  function ($form){
						console.log($form);
						var partnerEl = $form[0].querySelector('input[name="last_submission_referral_partner_code"]');
						partnerEl.value = window.referralCode;
						partnerEl.dispatchEvent(new Event('change'));
					},
					target: '.paperform-sba-wrap'
				});
			}, 1000);
		}
	}

</script>