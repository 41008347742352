import FormCheckbox    from '@/components/FormCheckbox'
import FormField       from '@/components/FormField'
import FormMultiselect from '@/components/FormMultiselect'

import validation from '@/helpers/validation'

export default {
	components: {
		FormCheckbox,
		FormField,
		FormMultiselect
	},

	computed: {
		formProgress () {
      let progress = 0,
          step = 100 / Object.keys(this.fields).length

      for (let field in this.fields) {
        if (this.validateField(field)) progress += step
      }

      return progress
    }
	},

	methods: {
		validateField (field) {
      let isValid = true

      const isRequired = Boolean(this.config[field].required)
      const validationRules = this.config[field].validation

      const dependedField = this.config[field].depends || ''
      
      if (isRequired) isValid = validation.required(this.fields[field])
      
      if (validationRules) {
        validationRules.forEach(rule => {
          if (!validation[rule](this.fields[field], this.fields[dependedField])) isValid = false
        })
      }

      return isValid
    }
	}
}