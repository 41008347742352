<template>
	<div class="row">
    <div class="col-12 col-md-8">
      <invitation />
    </div>

    <div class="col-12"></div>

    <div class="col-12 col-md-4">
      <data-preview :data="comissionPreview" icon="total" />
    </div>

    <div class="col-12" v-if="commission.length > 0">
    	<commission-table />
    </div>
  </div>
</template>


<script>
	
	import Invitation from '@/components/ProfileInvitation'
	import CommissionTable from '@/components/CommissionTable'
	import DataPreview from '@/components/DataPreview'

	import { mapActions, mapGetters } from 'vuex'

	export default {
		name: 'DashboardInvitedActivity',

		components: {
			Invitation,
			CommissionTable,
			DataPreview
		},

		computed: {
			...mapGetters([
				'commission',
				'profile'
			]),

			totalCommission () {
				return this.commission.reduce((accumulator, currentValue) => accumulator + currentValue.commission, 0)
			},

			comissionPreview () {
        return {
          title: `$${this.totalCommission} <small>Eearned</small>`,
          subtitle: `${this.commission.length} referrals invited`
        }
      },
		},

		methods: {
			...mapActions([
				'getCommissionData'
			])
		},

		async created () {
			console.log(this.profile)
			await this.getCommissionData()
		}
	}

</script>