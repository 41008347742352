<template>
  <div class="page-single page-signup">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 bg-white">
          <div class="signup-content">
            <h3 class="ml-lg-8">Welcome to the Janover Ventures Affiliate Partner Portal!</h3>
            <p class="ml-lg-8">Setup an account and start sending referrals,  building your referral pipeline, and tracking your submitted leads instantly.</p>
            <p class="ml-lg-8">Empower your website visitors, your email list audience, clients, buyers and colleagues with lighting fast execution and a better multifamily and commercial property financing experience. You can submit your first referral in less than 3 minutes!</p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="conainer-fluid py-8">
            <div class="row">
              <div class="col col-login mx-auto">
                
                <div class="text-center mb-6">
                  <img src="/assets/images/janover+ventures+logo.png" class="h-8" alt="">
                </div>

                <form class="card" method="post" novalidate @submit.prevent="handleFormSubmit">
                  <div class="progress">
                    <div class="progress-bar" 
                      :style="{ transform: `translateX(${-100 + formProgress }%)` }" 
                      role="progressbar" 
                      :aria-valuenow="formProgress" 
                      aria-valuemin="0" 
                      aria-valuemax="100"></div>
                  </div>

                  <div class="card-body p-6">
                    <div class="card-title text-center">Create new account</div>

                    <div class="alert alert-danger" role="alert" v-if="alert">
                      {{ alert }}
                    </div>

                    <component
                      v-for="(field, id) in config"
                      :is="field.type == 'multiselect' || field.type == 'checkbox' ? `form-${field.type}` : `form-field`"
                      :type="field.type"
                      :label="field.label"
                      :placeholder="field.placeholder"
                      :options="field.options"
                      :id="id"
                      :key="id"
                      :invalid="showAlerts && !validateField(id)"
                      v-model="fields[id]"
                    />

                    <div class="form-footer">
                      <button type="submit" class="btn btn-primary btn-block" :disabled="isLoading">Sign up</button>
                    </div>
                  </div>
                </form>
                
                <div class="text-center text-muted">
                  Already have an account? <router-link to="/login">Sign in</router-link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
	
  import route from '@/mixins/route.js'
  import form from '@/mixins/form.js'

  import config from '@/config/signup.js'
  import parseFormConfig from '@/helpers/parseFormConfig'

	export default {
		name: 'SignUp',

    mixins: [
      form,
      route
    ],

    data () {
      return {
        config,
        fields: parseFormConfig(config),
        showAlerts: false,
        isLoading: false,
        alert: '',
        timeout: null
      }
    },

    methods: {
      async handleFormSubmit () {
        if (!this.isLoading) {
          this.showAlerts = false
          this.alert = ''

          if (this.fields.password !== this.fields.passwordConfirmation) this.alert = 'Confirmation doesn\'t match your password'

          if (this.formProgress >= 100) {
            this.isLoading = true

            this.fields.utm = window.urlParams

            this.fields.invitedBy = this.$store.state.invitation

            this.fields.firstName = this.fields.firstName[0].toUpperCase() + this.fields.firstName.slice(1)
            this.fields.lastName = this.fields.lastName[0].toUpperCase() + this.fields.lastName.slice(1)

            try {
              dataLayer.push({
                'event': 'gtm.signUp',
                'email': this.fields.email
              });
              await this.$store.dispatch('signup', this.fields)
            } catch(e) {
              console.log(e)
              this.alert = e.message || e.error || e
              this.isLoading = false
            }
          } else {
            this.showAlerts = true
          }
        }
      }
    },

    watch: {
      'fields.password' () {
        clearTimeout(this.timeout)
        
        if (this.fields.password.length > 0 && this.fields.password.length < 6) {
          this.timeout = setTimeout(() => {
            this.alert = 'Your password should contain at least 6 characters'
          }, 1000)
        } else {
          this.alert = ''
        }
      }
    }
	}

</script>



<style lang="scss" scoped>
  .page-signup {
    padding: 0;
  }

  .card {
    position: relative;
    overflow: hidden;
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 0;
    transition: opacity .2s linear;

    .progress-bar {
      width: 100%;
      transition: transform .3s ease-in-out;
    }
  }

  .signup-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    z-index: 99;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    & > * {
      max-width: 320px;
      width: 100%;
      padding: 0 1rem;
    }

    @media (max-width: 991px) {
      position: static;
      height: auto;
      padding: 4rem 40px;
      width: 100%;
    }
  }
</style>
