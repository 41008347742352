export default {
	firstName: {
		type: 'text',
		placeholder: 'First name',
		label: 'First name',
		required: true,
		value: ''
	},

	lastName: {
		type: 'text',
		placeholder: 'Last name',
		label: 'Last name',
		required: true,
		value: ''
	},

	email: {
		type: 'email',
		placeholder: 'Email address',
		label: 'Email address',
		required: true,
		validation: [
			'isEmail'
		],
		value: ''
	},

	categories: {
		type: 'multiselect',
		placeholder: 'Select role',
		label: 'Role',
		options: ['Commercial Mortgage Banker', 'Commercial Property Owner/Operator', 'Commercial Real Estate Agent/Broker', 'Publisher', 'Residential Mortgage Banker', 'Residential Real Estate Agent/Broker', 'Other Professional'],
		required: true,
		value: []
	},

	password: {
		type: 'password',
		placeholder: 'Password',
		label: 'Password',
		required: true,
		validation: [
			'isPassword'
		],
		value: ''
	},

	passwordConfirmation: {
		type: 'password',
		placeholder: 'Password',
		label: 'Confirm your password',
		depends: 'password',
		required: true,
		validation: [
			'isPassword', 'isСonfirmation'
		],
		value: ''
	},

	termsAgreed: {
		type: 'checkbox',
		label: 'I agree with the <a href="/terms-and-conditions">terms & conditions</a> and <a href="/privacy-policy">privacy policy</a>.',
		required: true,
		value: false
	}
}