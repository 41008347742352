<template>
  <div class="referral-code-wrapper">
    <div class="card" v-if="profile.referralCode || profile.customLink">
      <div class="card-body">
        <h3 class="card-title">Your affiliate links</h3>
        <div class="links-wrap">
          <div>
            <div class="text-default">Commercial Loans</div>
            <a :href="cmLink" class="text-blue" target="_blank"><b>{{ cmLink }}</b></a>  
          </div>
          <div>
            <div class="text-default">Small Business Loans</div>
            <a :href="smbLink" class="text-blue" target="_blank"><b>{{ smbLink }}</b></a>
          </div>
        </div>
      </div>

      <div class="card-footer text-right" v-if="!minimized">
        <router-link class="btn btn-primary" to="/dashboard">Dashboard</router-link>
      </div>
    </div>

  	<form class="card" @submit.prevent="handleSubmit" v-else>
      <div class="card-body">
        <h3 class="card-title">Your Affiliate Link</h3>

        <div class="alert alert-danger" role="alert" v-if="alert">
          {{ alert }}
        </div>
        
        <div class="input-group input-group-flat">
          <span class="input-group-text">
            {{ link }}
          </span>
          <input type="text" class="form-control pl-1" v-model="code">
        </div>
      </div>
      
      <div class="card-footer text-right">
        <button type="submit" class="btn btn-primary" :disabled="isLoading">Create Link</button>
      </div>
    </form>
  </div>
</template>

<script>
	export default {
		name: 'ProfileReferralLink',

    props: {
      minimized: Boolean
    },

		computed: {
			profile () {
				return this.$store.getters.profile
			},

      cmLink () {
        return `${this.cmBaseURL}${this.profile.referralCode}`
      },

      smbLink () {
        return `${this.smbBaseURL}${this.profile.referralCode}`
      }
		},

    data () {
      return {
        code: '',
        isLoading: false,
        alert: false,
        cmBaseURL: process.env.VUE_APP_LANDER_URL,
        smbBaseURL: process.env.VUE_APP_LANDER_SMB_URL
      }
    },

    methods: {
      async handleSubmit () {
        this.isLoading = true

        try {
          await this.$store.dispatch('updateCode', {
            referralCode: this.code
          })

          this.isLoading = false
        } catch(e) {
          console.log(e)
          this.alert = e.message || e.error || e
          this.isLoading = false
        }
      }
    }
	}
</script>

<style lang="scss" scoped>
  
  .input-group-flat {
    span {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .links-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > * {
      width: 50%;
    }

    @media (max-width: 767px) {
      display: block;

      & > * {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

</style>