<template>
  <div class="page-single page-login">
    <div class="container">
      <div class="row">
        <div class="col col-login mx-auto">
          <div class="text-center mb-6">
            <img src="/assets/images/janover+ventures+logo.png" class="h-8" alt="">
          </div>
          
          <form class="card" action="" method="post" @submit.prevent="handleFormSubmit">
            <div class="card-body p-6">
              <div class="card-title text-center">Login to your account</div>

              <div class="alert alert-danger" role="alert" v-if="alert">
                {{ alert }}
              </div>
              
              <component
                v-for="(field, id) in config"
                :is="field.type == 'multiselect' || field.type == 'checkbox' ? `form-${field.type}` : `form-field`"
                :type="field.type"
                :label="field.label"
                :placeholder="field.placeholder"
                :options="field.options"
                :id="id"
                :key="id"
                :invalid="showAlerts && !validateField(id)"
                :addon="field.addon"
                v-model="fields[id]"
              />

              <div class="form-footer">
                <button
                  type="submit"
                  class="btn btn-primary btn-block"
                  :disabled="isLoading">Sign in</button>
              </div>
            </div>
          </form>
          
          <div class="text-center text-muted">
            Don't have an account yet? <router-link to="/sign-up">Sign up</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>

  import route from '@/mixins/route.js'
  import form from '@/mixins/form.js'

  import config from '@/config/login.js'
  import parseFormConfig from '@/helpers/parseFormConfig'
	
	export default {
		name: 'Login',

    mixins: [
      form,
      route
    ],

    data () {
      return {
        config,
        fields: parseFormConfig(config),
        isLoading: false,
        showAlerts: false,
        alert: ''
      }
    },

		methods: {
			async handleFormSubmit () {
        if (!this.isLoading) {
          this.showAlerts = false
          this.alert = ''

          if (this.formProgress >= 100) {
            this.isLoading = true

            try {
              await this.$store.dispatch('login', this.fields)
            } catch(e) {
              console.log(e)
              this.alert = e.error || e
              this.isLoading = false
            }
          } else {
            this.showAlerts = true
          }
        }
      }
		}
	}

</script>
