export default function(icon) {
	switch (icon) {
    case 'leads':
      return 'fe-users'
    case 'contacts':
      return 'fe-message-square'
    case 'deals':
      return 'fe-briefcase'
    case 'total':
      return 'fe-dollar-sign'
    case 'views':
      return 'fe-eye'
    case 'duplicates':
      return 'fe-copy'
    case 'requests':
      return 'fe-target'
    default:
      return ''
  }
}