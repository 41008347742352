import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import Login from '../views/Login'
import SignUp from '../views/SignUp'
import Dashboard from '../views/Dashboard'
import LeadCreation from '../views/LeadCreation'
import Profile from '../views/Profile'
import PasswordRecovery from '../views/PasswordRecovery'
import Terms from '../views/Terms'
import PrivacyPolicy from '../views/PrivacyPolicy'
import Resources from '../views/Resources'
import Contact from '../views/Contact'
import Faq from '../views/Faq'
import Invitation from '../views/Invitation'
import PageNotes from '../views/PageNotes'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/profile'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/forgot-password',
    name: 'PasswordRecovery',
    component: PasswordRecovery,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/create-lead',
    name: 'LeadCreation',
    component: LeadCreation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/invitation/:referralCode',
    name: 'Invitation',
    component: Invitation,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/notes/:id',
    name: 'PageNotes',
    component: PageNotes,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const accessibleRoutes = ['Login', 'SignUp', 'PasswordRecovery', 'Invitation']

  if (window.dataLayer) window.dataLayer.push({
    'event': 'gtm.pageView',
    'page': to.name
  })

  if (store.isReady) {
    if (accessibleRoutes.indexOf(to.name) < 0 && !store.getters.isAuthenticated) next({ name: 'Login' })
    else next()
  } else next()
})

export default router
