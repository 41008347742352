<template>
	<page-simple>
		<div class="mb-3 mb-md-5 mt-6">
			<div class="container">
				<h1 class="page-title pb-5">{{ id }} updates</h1>

				<template v-if="!notes || notes.length < 1">
					<notification message="There weren't updates yet" type="warning" />
				</template>

				<template v-else>
					<div class="note" v-for="note in sortedNotes">
						<p class="pb-2">{{ getDateString(note.updated_at) }}</p>
						<div class="card">
							<div class="card-body" v-html="note.content.replace(/[\n\r]/g,'<br>')"></div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</page-simple>
</template>



<script>
	
	import PageSimple from '@/components/PageSimple'
	import Notification from '@/components/Notification'

	import { mapGetters, mapActions } from 'vuex'

	export default {
		components: {
			PageSimple,
			Notification
		},

		computed: {
			id () {
				return this.$route.params.id
			},

			sortedNotes () {
				function compare(a, b) {
		      if (a.updated_at < b.updated_at)
		        return -1
		      if (a.updated_at > b.updated_at)
		        return 1
		      return 0
		    }

				if (this.notes && this.notes.length > 0) return this.notes.sort(compare).reverse()
				return []
			}
		},

		data () {
			return {
				notes: null
			}
		},

		methods: {
			...mapActions([
				'getNotes'
			]),

			getDateString (d) {
        const date = new Date(d).toDateString()

        return `${date.slice(4, date.length)}`
      },
		},

		async created () {
			try {
				this.notes = await this.getNotes(this.id)
			} catch(e) {
				console.log(e)
			}
		}
	}

</script>
