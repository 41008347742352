<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Invited referrals</h3>
    </div>

    <div class="table-responsive">
      <table class="table card-table table-striped table-vcenter">
        <template>
          <thead>
            <tr>
              <th>Name</th>
              <th>Code</th>
              <th>Your commission</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in commission">
              <td>{{ item.firstName }} {{ item.lastName }}</td>
              <td>{{ item.referralCode }}</td>
              <td>{{ formatNumber(item.commission) }}</td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
	
	import { mapGetters } from 'vuex'
	import formatNumber from '@/helpers/formatNumber.js'

	export default {
		name: 'CommissionTable',

		computed: {
			...mapGetters([
				'commission'
			])
		},

		methods: {
			formatNumber (number) {
        return formatNumber(number, '$0,0')
      }
		}
	}

</script>