export default {
	banners: {
		label: 'Banner Ads',
		content: [
			{
				label: '336x280',
				content: [
					'/assets/banners/1/1_336x280.png',
					'/assets/banners/2/2_336x280.png',
					'/assets/banners/3/3_336x280.png',
					'/assets/banners/4/4_336x280.png',
					'/assets/banners/5/5_336x280.png',
					'/assets/banners/6/6_336x280.png',
					'/assets/banners/7/7_336x280.png',
					'/assets/banners/8/8_336x280.png',
					'/assets/banners/9/9_336x280.png'
				]
			},
			{
				label: '250x250',
				content: [
					'/assets/banners/1/1_250x250.png',
					'/assets/banners/2/2_250x250.png',
					'/assets/banners/3/3_250x250.png',
					'/assets/banners/4/4_250x250.png',
					'/assets/banners/5/5_250x250.png',
					'/assets/banners/6/6_250x250.png',
					'/assets/banners/7/7_250x250.png',
					'/assets/banners/8/8_250x250.png',
					'/assets/banners/9/9_250x250.png'
				]
			},
			{
				label: '160x600',
				content: [
					'/assets/banners/1/1_160x600.png',
					'/assets/banners/2/2_160x600.png',
					'/assets/banners/3/3_160x600.png',
					'/assets/banners/4/4_160x600.png',
					'/assets/banners/5/5_160x600.png',
					'/assets/banners/6/6_160x600.png',
					'/assets/banners/7/7_160x600.png',
					'/assets/banners/8/8_160x600.png',
					'/assets/banners/9/9_160x600.png'
				]
			},
			{
				label: '300x250',
				content: [
					'/assets/banners/1/1_300x250.png',
					'/assets/banners/2/2_300x250.png',
					'/assets/banners/3/3_300x250.png',
					'/assets/banners/4/4_300x250.png',
					'/assets/banners/5/5_300x250.png',
					'/assets/banners/6/6_300x250.png',
					'/assets/banners/7/7_300x250.png',
					'/assets/banners/8/8_300x250.png',
					'/assets/banners/9/9_300x250.png'
				]
			},
			{
				label: '300x600',
				content: [
					'/assets/banners/1/1_300x600.png',
					'/assets/banners/2/2_300x600.png',
					'/assets/banners/3/3_300x600.png',
					'/assets/banners/4/4_300x600.png',
					'/assets/banners/5/5_300x600.png',
					'/assets/banners/6/6_300x600.png',
					'/assets/banners/7/7_300x600.png',
					'/assets/banners/8/8_300x600.png',
					'/assets/banners/9/9_300x600.png'
				]
			},
			{
				label: '320x100',
				content: [
					'/assets/banners/1/1_320x100.png',
					'/assets/banners/2/2_320x100.png',
					'/assets/banners/3/3_320x100.png',
					'/assets/banners/4/4_320x100.png',
					'/assets/banners/5/5_320x100.png',
					'/assets/banners/6/6_320x100.png',
					'/assets/banners/7/7_320x100.png',
					'/assets/banners/8/8_320x100.png',
					'/assets/banners/9/9_320x100.png'
				]
			},
			{
				label: '320x50',
				content: [
					'/assets/banners/1/1_320x50.png',
					'/assets/banners/2/2_320x50.png',
					'/assets/banners/3/3_320x50.png',
					'/assets/banners/4/4_320x50.png',
					'/assets/banners/5/5_320x50.png',
					'/assets/banners/6/6_320x50.png',
					'/assets/banners/7/7_320x50.png',
					'/assets/banners/8/8_320x50.png',
					'/assets/banners/9/9_320x50.png'
				]
			},
			{
				label: '728x90',
				content: [
					'/assets/banners/1/1_728x90.png',
					'/assets/banners/2/2_728x90.png',
					'/assets/banners/3/3_728x90.png',
					'/assets/banners/4/4_728x90.png',
					'/assets/banners/5/5_728x90.png',
					'/assets/banners/6/6_728x90.png',
					'/assets/banners/7/7_728x90.png',
					'/assets/banners/8/8_728x90.png',
					'/assets/banners/9/9_728x90.png'
				]
			}
		]	
	},
	socialMedia: {
		label: 'Social Media Content',
		content: []	
	},
	emailSwipes: {
		label: 'Email Swipes',
		content: []	
	},
	ppc: {
		label: 'PPC Keywords / Ads',
		content: []	
	},
}