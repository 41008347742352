import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      routeIsReady: false
    }
  },

	computed: {
		...mapGetters([
      'isAuthenticated'
    ]),

    requiresAuth () {
      return this.$route.meta.requiresAuth
    }
	},

  methods: {
    checkState () {
      if (this.requiresAuth) {
        if (!this.isAuthenticated) this.$router.push('/login')
        else this.routeIsReady = true
      } else {
        if (this.isAuthenticated) this.$router.push(this.$store.state.redirect)
        else this.routeIsReady = true
      }
    }
  },

  watch: {
    isAuthenticated () {
      this.checkState()
    }
  },

  created () {
    this.checkState()
  }
}