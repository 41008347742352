import Vue from 'vue'
import Vuex from 'vuex'

import api from '@/api'
import cookies from '@/helpers/cookies'
import logger from '@/helpers/logger'

import { AUTH_TOKEN } from '@/constants/cookies'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  	isAuthenticated: false,
    isReady: false,
    profile: null,
    token: null,
    notifications: [],
    leads: [],
    contacts: [],
    deals: [],
    duplicates: [],
    redirect: '/',
    invitation: '',
    commission: []
  },

  mutations: {
    profile (state, profile) {
      state.profile = profile
    },

    isReady (state, value) {
      state.isReady = value
    },

    token (state, token) {
      state.token = token
    },

    isAuthenticated (state, value) {
      state.isAuthenticated = value
    },

    notification (state, payload) {
      state.notifications = payload
    },

    leads (state, payload) {
      state.leads = payload
    },

    contacts (state, payload) {
      state.contacts = payload
    },

    deals (state, payload) {
      state.deals = null
      state.deals = payload
    },

    duplicates (state, payload) {
      state.duplicates = payload
    },

    redirect (state, path) {
      if (path !== '/login' && path !== '/sign-up') state.redirect = path
    },

    invitation (state, referralCode) {
      state.invitation = referralCode
    },

    commission (state, payload) {
      state.commission = payload
    }
  },

  actions: {
    async init ({ commit, dispatch }, path) {
      logger('store/init()')

      try {
        const token = cookies.get(AUTH_TOKEN)

        if (token) {
          api.setAuthorizationHeaders(token)
          commit('token', token)
          await dispatch('getProfile')
        }

        commit('redirect', path)
        commit('isReady', true)

        return true
      } catch(e) {
        cookies.set(AUTH_TOKEN, '', -1)

        commit('token', null)
        commit('isReady', true)
        
        return true
      }
        
    },

    updateProfileState ({ commit }, profile) {
      logger('store/updateProfileState()', profile)

      commit('profile', profile)
      commit('isAuthenticated', true)

      return true
    },

    updateCookie ({}, token) {
      logger('store/updateCookie()', token)

      cookies.set(AUTH_TOKEN, token)
    },

    async signup ({ commit, dispatch }, payload) {
      logger('store/signup()', payload)

      try {
        let response = await api.createProfile(payload)

        if (response.token) {
          dispatch('updateCookie', response.token)
          commit('token', response.token)
        }
        if (response.profile) dispatch('updateProfileState', response.profile)
      } catch(e) {
        throw e
      }      
    },

    async login ({ commit, dispatch }, payload) {
      logger('store/login()', payload)

      try {
        let response = await api.login(payload)

        if (response.token) {
          dispatch('updateCookie', response.token)
          commit('token', response.token)
        }
        
        if (response.profile) {
          await dispatch('updateProfileState', response.profile)
        }

        return
      } catch(e) {
        throw e
      }      
    },

    async getProfile ({ dispatch, state }) {
      logger('store/getProfile()')

      try {
        let profile = await api.getProfile()

        if (profile) await dispatch('updateProfileState', profile)
        if (state.leads.length < 1) {
          await dispatch('getDeals')
          await dispatch('getContacts')
          await dispatch('getDuplicates')
        }
      } catch(e) {
        throw e
      }
    },

    async updateProfile ({ dispatch }, payload) {
      logger('store/updateProfile()', payload)

      try {
        let profile = await api.updateProfile(payload)

        if (profile) await dispatch('updateProfileState', profile)

        return true
      } catch(e) {
        throw e
      }
    },

    async updateCode ({ dispatch }, payload) {
      logger('store/updateCode()', payload)

      try {
        let profile = await api.updateCode(payload)

        if (profile) await dispatch('updateProfileState', profile)

        return true
      } catch(e) {
        throw e
      }
    },

    async updatePassword ({ dispatch, commit }, payload) {
      logger('store/updatePassword()', payload)

      try {
        let token = await api.updatePassword(payload)

        if (token) {
          dispatch('updateCookie', token)
          commit('token', token)
        }

        return true
      } catch(e) {
        throw e
      }
    },

    async getStats ({ dispatch }) {
      logger('store/getStats()')

      try {
        await dispatch('getLeads')
        await dispatch('getContacts')
        await dispatch('getDeals')
        await dispatch('getDuplicates')

        return true
      } catch(e) {
        throw e
      }
    },

    async getLeads ({ commit }) {
      logger('store/getLeads()')

      try {
        let leads = await api.getLeads()

        commit('leads', leads)

        return true
      } catch(e) {
        throw e
      }
    },

    async getDeals ({ commit }) {
      logger('store/getDeals()')

      try {
        let deals = await api.getDeals()

        commit('deals', deals)

        return true
      } catch(e) {
        throw e
      }
    },

    async getContacts ({ commit }) {
      logger('store/getContacts()')

      try {
        let contacts = await api.getContacts()

        commit('contacts', contacts)

        return true
      } catch(e) {
        throw e
      }
    },

    async getDuplicates ({ commit }) {
      logger('store/getDuplicates()')

      try {
        let duplicates = await api.getDuplicates()

        commit('duplicates', duplicates)

        return true
      } catch(e) {
        throw e
      }
    },

    async requestNewPassword ({}, payload) {
      logger('store/requestNewPassword()', payload)

      try {
        await api.resetPassword(payload)
        return true
      } catch(e) {
        throw e
      }
    },

    async updateW9 ({ dispatch }, payload) {
      logger('store/updateW9()', payload)

      try {
        const url = await api.sendFile(payload)

        await dispatch('updateProfile', {
          w9: url
        })
        
        return true
      } catch(e) {
        throw e
      }
    },

    async deleteW9 ({ dispatch }) {
      logger('store/deleteW9()')

      try {
        await dispatch('updateProfile', {
          w9: null
        })
        
        return true
      } catch(e) {
        throw e
      }
    },

    async updateImage ({ dispatch }, payload) {
      logger('store/updateImage()', payload)

      try {
        const url = await api.sendFile(payload)

        await dispatch('updateProfile', {
          image: url
        })
        
        return true
      } catch(e) {
        throw e
      }
    },

    notify ({ commit }, payload) {
      commit('notification', payload)
      window.scrollTo(0, 0)
    },

    logout () {
      cookies.set(AUTH_TOKEN, '', -1)
      window.location.reload()
    },

    async contactSupport ({}, payload) {
      logger('store/contactSupport()', payload)

      try {
        const result = await api.contactSupport(payload)        
        return true
      } catch(e) {
        throw e
      }
    },

    async getContent ({}, page) {
      logger('store/getContent()', page)

      try {
        const content = await api.getContent(page)        
        return content
      } catch(e) {
        throw e
      }
    },

    async getCommissionData ({ commit }) {
      logger('store/getCommissionData()')

      try {
        const commission = await api.getCommissionData()

        commit('commission', commission)

        return true
      } catch(e) {
        throw e
      }
    },

    async getNotes ({}, id) {
      logger('store/getNotes', id)

      try {
        const notes = await api.getNotes(id)

        return notes
      } catch(e) {
        throw e
      }
    }
  },

  getters: {
  	isAuthenticated (state) {
  		return state.isAuthenticated
  	},
    isReady (state) {
      return state.isReady
    },
    profile (state) {
      return state.profile
    },
    token (state) {
      return state.token
    },
    leads (state) {
      return state.leads
    },
    deals (state) {
      return state.deals
    },
    duplicates (state) {
      return state.duplicates
    },
    contacts (state) {
      return state.contacts
    },
    commission (state) {
      return state.commission
    },
    affiliateLink (state) {
      if (!state.profile) return ''
      else if (state.profile.referralCode) return `${process.env.VUE_APP_LANDER_URL}${state.profile.referralCode}`
      return ''
    }
  }
})
