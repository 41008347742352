<template>
	<page-simple v-if="routeIsReady">
		<div class="my-3 my-md-5">
	    	<div class="container">
				<div class="tabs mb-5">
		          <button
		            class="btn btn-square btn-secondary" 
		            :class="{ 'is-active': activeTab == 'cm' }"
		            @click="activeTab = 'cm'"
		          >Investment Property</button>
		          
		          <button
		            class="btn btn-square btn-secondary ml-2" 
		            :class="{ 'is-active': activeTab == 'sba' }"
		            @click="activeTab = 'sba'"
		          >Small Business</button>
		        </div>

				<keep-alive>
					<component :is="activeTab == 'cm' ? 'cm-form': 'sba-form'"/>
				</keep-alive>
			</div>
		</div>
	</page-simple>
</template>

<script>
	
	import PageSimple from '@/components/PageSimple'
	
	import CmForm from './cm.vue'
	import SbaForm from './sba.vue'
	
	import route from '@/mixins/route'

	export default {
		name: 'LeadCreationPage',

		mixins: [route],

		components: {
			PageSimple,
			CmForm,
			SbaForm
		},

		data () {
			return {
				activeTab: 'cm'
			}
		}
	}

</script>

<style lang="scss" scoped>
	.btn.is-active {
		box-shadow: 0 0 0 1px var(--azure);
	}
</style>