<template>
  <div class="avatar-box" :class="{ 'is-loading': isLoading }">
    <input
      class="avatar-box__file"
      type="file"
      name="avatar"
      @change="handleAvatarChange"
    >
    <template v-if="!profile.image">
      <span class="avatar avatar-azure avatar-xl">{{ avatarPlaceholder }}</span>
      <span class="avatar-box__hover bg-azure-lighter">
        <span class="fe fe-camera text-azure"></span>
      </span>
    </template>
    
    <template v-else>
      <span class="avatar avatar-xl" :style="{ 'background-image': `url(${profile.image})`}"></span>
    </template>
  </div>
</template>



<script>

  import { mapActions } from 'vuex'
  import validation from '@/helpers/validation.js'

  const messages = {
    alert (message) {
      return {
        message,
        type: 'danger',
        timer: 5000
      }
    },

    badFile: {
      message: 'Please select an image file (jpg or png) up to 2mb',
      type: 'warning',
      timer: 10000
    }
  }
  
  export default {
    name: 'ProfileAvatarBox',

    computed: {
      profile () {
        return this.$store.getters.profile
      },

      avatarPlaceholder () {
        let placeholder = ''

        if (this.profile.firstName) placeholder += this.profile.firstName.split('')[0]
        if (this.profile.lastName) placeholder += this.profile.lastName.split('')[0]

        return placeholder
      }
    },

    data () {
      return {
        file: null,
        isLoading: false
      }
    },

    methods: {
      ...mapActions([
        'notify'
      ]),

      async handleAvatarChange (e) {
        if (!this.isLoading) {
          
          this.file = (e.target.files[0]) ? e.target.files[0] : null

          if (this.file) {
            
            if (validation.isImage(this.file) && this.file.size <= 2000000) {

              this.isLoading = true

              try {
                const formData = new FormData()

                formData.append('file', this.file)
                formData.append('postfix', 'avatar')

                await this.$store.dispatch('updateImage', formData)
                this.isLoading = false
                this.file = null
              } catch(e) {
                this.notify(messages.alert(e.message || e.error || e))
                this.isLoading = false
              }

            } else {
              this.notify(messages.badFile)
            }
          }
        }
      }
    }
  }

</script>



<style lang="scss" scoped>
  .avatar-box {
    position: relative;
    border-radius: 100%;
    overflow: hidden;

    &.is-loading {
      opacity: 0.6;
      pointer-events: none;
    }

    $this: &;

    .avatar > * {
      transition: opacity .3s ease-in-out;
    }

    &__hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 100%;
      font-size: 24px;
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }

    &__file {
      position: absolute;
      right: 0;
      top: 0;
      width: 300%;
      height: 100%;
      z-index: 99;
      cursor: pointer;
      opacity: 0;
    }

    &:hover {
      #{$this}__hover {
        opacity: 1;
      }

      .avatar > * {
        opacity: 0;
      }
    }
  }
</style>
