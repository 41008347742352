<template>
  <page-simple v-if="routeIsReady">
    <div class="my-3 my-md-5">
      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-4">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Resources center</h3>
              </div>
              <div class="card-body">
                <button
                  v-for="(value, key) in config"
                  :key="`button-${key}`"
                  :disabled="value.content.length <= 0"
                  @click="activeTabe = key"
                  type="button"
                  class="btn btn-secondary btn-block"
                >{{ value.label }}</button>
              </div>
            </div>
          </div> -->
          
          <div
            v-for="(value, key) in config"
            :key="`tab-${key}`"
            v-show="activeTab == key"
            class="col-lg-12"
          >
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">{{ value.label }}</h3>
              </div>

              <div class="card-body" v-for="item in value.content">
                <p>{{ item.label }}</p>

                <div class="row">
                  <div class="col-md-6" v-for="subitem in item.content">
                    <div class="card">
                      <div class="card-body">
                        <div class="banner-wrap" v-html="getLink(subitem)">
                        </div>
                      </div>
                      <div class="card-footer">
                        <textarea class="form-control">{{ getLink(subitem) }}</textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-simple>
</template>



<script>

  import PageSimple from '@/components/PageSimple'

  import { mapGetters } from 'vuex'

  import route from '@/mixins/route.js'

  import config from './config.js'
  
  export default {
    name: 'Resources',

    mixins: [
      route
    ],

    computed: {
      ...mapGetters([
        'profile',
        'affiliateLink'
      ]),
    },

    components: {
      PageSimple
    },

    data () {
      return {
        config,
        activeTab: Object.keys(config)[0]
      }
    },

    methods: {
      getLink (banner) {
        return `<a href="${this.affiliateLink}"><img src="${window.location.origin + banner}" /></a>`
      }
    }
  }

</script>



<style lang="scss" scoped>
  .banner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ textarea.form-control {
    height: 110px;
  }
</style>
