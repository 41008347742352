<template>
	<div></div>
</template>

<script>
	export default {
		name: 'PageInvitation',

		created () {
			if (!this.$store.getters.isAuthenticated) {
				this.$store.commit('invitation', this.$route.params.referralCode)
				this.$router.push('/sign-up')
			} else {
				this.$router.push('/')
			}
		}
	}
</script>