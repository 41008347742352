<template>
	<div>
    <notification
    	v-for="(notification, index) in notifications"
      class="mb-6"
      :type="notification.type"
      :message="notification.message"
      :timer="notification.timer"
      :key="index" />
  </div>
</template>

<script>
	import Notification from '@/components/Notification'

	export default {
		name: 'NotificationsFeed',

		props: {
			notifications: Array
		},

		components: {
			Notification
		}
	}
</script>