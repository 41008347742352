export default {
	email: {
		type: 'email',
		placeholder: 'Email',
		label: 'Email address',
		required: true,
		validation: [
			'isEmail'
		],
		value: ''
	},

	password: {
		type: 'password',
		placeholder: 'Password',
		label: 'Password',
		required: true,
		validation: [
			'isPassword'
		],
		value: '',
		addon: {
			label: 'I forgot my password',
			link: '/forgot-password'
		}
	},

	saveSession: {
		type: 'checkbox',
		label: 'Remember me',
		required: false,
		value: false
	}
}