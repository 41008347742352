<template>
	<page-simple>
		<div class="mb-3 mb-md-5 mt-6">
			<div class="container">
				<div class="page-head">
					<h1 class="mb-6"><strong>FAQ</strong></h1>

					<template v-if="content && content.articles">
						<div class="row">
							<div class="col-12 col-md-4 hidden-xs">
								<div class="list-group list-group-transparent mb-0">
									<a
										v-for="(article, index) in content.articles"
										:href="`#article-${index+1}`"
										@click.prevent="scrollTo(index)"
										class="list-group-item list-group-item-action pl-0 pt-0 pb-3">
										{{ article.title }}
									</a>
                </div>
							</div>

							<div class="col-12 col-md-8">
								<div class="faq-item" v-for="(article, index) in content.articles" :data-article="index">
									<h3>{{ article.title }}</h3>

									<div class="card pt-4 px-4 pb-1 mb-8">
										<p v-html="article.body"></p>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</page-simple>
</template>



<script>
	
	import PageSimple from '@/components/PageSimple'

	export default {
		name: 'FaqPage',

		components: {
			PageSimple
		},

		data () {
			return {
				content: null
			}
		},

		methods: {
			async getContent () {
				this.content = await this.$store.dispatch('getContent', 'faq')
			},

			scrollTo (index) {
				window.scrollTo({
				  top: document.querySelector(`.faq-item[data-article="${index}"`).offsetTop + 140,
				  behavior: 'smooth'
				})
			}
		},

		created () {
			this.getContent()
		}
	}

</script>



<style scoped>
	p+h3 {
		margin-top: 1em;
	}
</style>