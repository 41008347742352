<template>
  <div class="invitation-wrapper">
    <div class="card">
      <div class="card-status bg-green"></div>
      <div class="card-body">
        <p>Invite a friend or colleague to become a referral partner<br/>and earn up to $10,000 from the leads they send through our portal!</p>
        <a :href="invitationLink" target="_blank"><b>{{ invitationLink }}</b></a>
      </div>

      <div class="custom-tooltip">
        <i class="fe fe-info"></i>
        <p>Earn up to 5% of referral partner commission earned on all eligible closed deals, up to $10,000.</p>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		name: 'ProfileInvitationLink',

		computed: {
			profile () {
				return this.$store.getters.profile
			},

      invitationLink () {
        return `${window.location.origin}/invitation/${this.profile.referralCode}`
      }
		}
	}
</script>

<style lang="scss" scoped>
  .custom-tooltip {
    color: var(--azure);
    position: absolute;
    text-align: center;
    width: 20px;
    height: 20px;
    top: 8px;
    right: 8px;
    z-index: 9;
    font-size: 20px;
    line-height: 1;

    p {
      display: none;
      position: absolute;
      background: white;
      box-shadow: 0 0 2px rgba(0,0,0,0.2);
      width: 280px;
      font-size: 12px;
      color: var(--gray);
      bottom: 100%;
      margin-bottom: 8px;
      right: 0;
      padding: 10px;
      box-sizing: border-box;
      text-align: left;
      border-radius: 4px;
    }

    &:hover {
      p {
        display: block;
      }
    }
  }
</style>