<template>
  <div id="chart"></div>
</template>

<script>

  import c3 from 'c3'
  
  export default {
    props: {
      data: Object
    },

    data () {
      return {
        chart: null
      }
    },

    computed: {
      dates () {
        const dates = new Set()

        for (let key in this.data) {
          this.data[key].forEach(item => {
            dates.add(item.created_date || item.created.slice(0, 10))
          })
        }

        return [...dates]
      },

      convertedData () {
        const converted = {}

        for (let key in this.data) {
          converted[key] = {}

          this.data[key].forEach(item => {
            let date = item.created_date || item.created.slice(0, 10)

            if (!converted[key][date]) converted[key][date] = []
            converted[key][date].push(item)
          })
        }

        return converted
      },

      chartColumns () {
        const columns = []

        columns[0] = ['x'].concat(this.dates)

        for (let key in this.convertedData) {
          let values = []

          this.dates.forEach(date => {
            if (this.convertedData[key][date]) values.push(this.convertedData[key][date].length)
            else values.push(0)
          })

          columns.push([key].concat(values))
        }

        return columns
      }
    },

    methods: {
      initChart () {
        if (this.chart) this.chart = this.chart.destroy()

        this.chart = c3.generate({
          bindto: '#chart',
          labels: true,
          data: {
            x: 'x',
            columns: this.chartColumns,
            type: 'bar',
            colors: {
              leads: '#f1c40f',
              contacts: '#467fcf',
              deals: '#45aaf2',
              duplicates: '#cd201f'
            },
          },
          axis: {
            x: {
              type: 'timeseries',
              tick: {
                format: '%Y-%m-%d'
              }
            }
          },
          legend: {
            show: false
          },
          zoom: {
            enabled: true
          }
        })
      }
    },

    watch: {
      data () {
        this.initChart()
      }
    },

    mounted () {
      this.initChart()
    }
  } 

</script>
