<template>
	<form class="card" novalidate @submit.prevent="handleSubmit">
		<template v-if="isToggled">
			<div class="card-body">
				<notification
					class="mb-5"
					type="danger"
					:message="alert"
					v-if="alert" />

				<div class="form-group">
					<label class="form-label" for="oldPassword">Old password</label>
					
					<input
						v-model="oldPassword"
						:class="{ 'is-invalid': showAlerts && !oldPasswordIsValid }"
						class="form-control"
						placeholder="Old password"
						type="password"
						id="oldPassword">
				</div>

				<div class="form-group">
					<label class="form-label" for="newPassword">New password</label>
					
					<input
						v-model="newPassword"
						:class="{ 'is-invalid': showAlerts && !newPasswordIsValid }"
						class="form-control"
						placeholder="New password"
						type="password"
						id="newPassword">
				</div>

				<div class="form-group">
					<label class="form-label" for="newPasswordConfirmation">Confirm new password</label>
					
					<input
						v-model="newPasswordConfirmation"
						:class="{ 'is-invalid': showAlerts && !newPasswordConfirmed }"
						class="form-control"
						placeholder="New password"
						type="password"
						id="newPasswordConfirmation">
				</div>
			</div>

			<div class="card-footer">
				<button
					type="submit"
					class="btn btn-primary btn-block">
					Update password
				</button>
			</div>
		</template>

		<div class="card-footer" v-else>
			<button
				@click="toggleForm"
				type="button"
				class="btn btn-secondary btn-block">
				Update password
			</button>
		</div>
	</form>
</template>

<script>

	import Notification from '@/components/Notification'
	
	export default {
		name: 'ProfilePasswordUpdate',

		data () {
			return {
				isToggled: false,
				oldPassword: '',
				newPassword: '',
				newPasswordConfirmation: '',
				isLoading: false,
				showAlerts: false,
				alert: ''
			}
		},

		components: {
			Notification
		},

		computed: {
			oldPasswordIsValid () {
				return this.oldPassword.length > 0
			},

			newPasswordIsValid () {
				return this.newPassword.length > 5
			},

			newPasswordConfirmed () {
				return this.newPasswordIsValid && this.newPassword === this.newPasswordConfirmation
			},
		},

		methods: {
			toggleForm () {
				this.isToggled = true
			},

			async handleSubmit () {
				if (!this.isLoading) {
					this.showAlerts = false
					this.alert = ''

					if (this.oldPasswordIsValid && this.newPasswordIsValid && this.newPasswordConfirmed) {
						this.isLoading = true

						try {
							await this.$store.dispatch('updatePassword', {
								oldPassword: this.oldPassword,
								newPassword: this.newPassword
							})

							this.isToggled = false
							this.isLoading = false

							this.oldPassword = ''
							this.newPassword = ''							
							this.newPasswordConfirmation = ''

							this.$store.dispatch('notify', {
								message: 'Your password has been updated successfully.',
								timer: 10000,
								type: 'success'
							})
						} catch(e) {
							console.log(e)
							this.alert = e.message || e.error || e
							this.isLoading = false
						}
					} else {
						this.showAlerts = true
					}
				}
			}
		}
	}

</script>

<style lang="scss" scoped>
	.card-footer:first-child {
		border-top: none;
	}
</style>