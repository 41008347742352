import axios from 'axios'
import jwtDecode from 'jwt-decode'

import logger from '@/helpers/logger'

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`
})

export default {
	setAuthorizationHeaders (token) {
		logger('api/setAuthorizationHeaders', token)

		instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
	},

	async createProfile (payload) {
		logger('api/createProfile', payload)
		
		try {
			const { data } = await instance.post('/profile', payload)

			if (data.token) {
				this.setAuthorizationHeaders(data.token)
			}

			return data
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async login (payload) {
		logger('api/login', payload)

		try {
			const { data } = await instance.post('/login', payload)

			if (data.token) {
				this.setAuthorizationHeaders(data.token)
			}

			return data
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async getProfile () {
		logger('api/getProfile')

		try {
			const { data } = await instance.get(`/profile`)

			return data.profile
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async updateProfile (payload) {
		logger('api/updateProfile', payload)

		try {
			const { data } = await instance.put(`/profile`, payload)

			return data.profile
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async updateCode (payload) {
		logger('api/updateCode', payload)

		try {
			const { data } = await instance.put(`/referral-code`, payload)

			return data.profile
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async updatePassword (payload) {
		logger('api/updatePassword', payload)

		try {
			const { data } = await instance.put(`/password`, payload)

			return data.token
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async getLeads () {
		logger('api/getLeads')

		try {
			const { data } = await instance.get(`/leads`)

			return data.leads
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async getContacts () {
		logger('api/getContacts')

		try {
			const { data } = await instance.get(`/contacts`)

			return data.contacts
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async getDeals () {
		logger('api/getDeals')

		try {
			const { data } = await instance.get(`/deals`)

			return data.deals
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async getDuplicates () {
		logger('api/getDuplicates')

		try {
			const { data } = await instance.get(`/duplicates`)

			return data.duplicates
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async resetPassword (payload) {
		logger('api/resetPassword', payload)

		try {
			const { data } = await instance.post(`/reset-password`, payload)

			return !data.error
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async sendFile (payload) {
		logger('api/sendFile')

		try {
			const { data } = await instance.post(`/file`, payload)

			return data.path
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async contactSupport (payload) {
		logger('api/contactSupport')

		try {
			const { data } = await instance.post(`/contact`, payload)

			return data
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async getContent (page) {
		logger('api/getContent', page)

		try {
			const { data } = await instance.get(`/content?type=${page}`)

			return data.content
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async getCommissionData () {
		logger('api/getCommissionData')

		try {
			const { data } = await instance.get(`/commission`)

			return data.commission
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},

	async getNotes (id) {
		logger('api/getNotes')

		try {
			const { data } = await instance.get(`/notes?id=${id}`)

			return data.notes
		} catch(e) {
			console.log(e.response)
			throw e.response.data
		}
	},	
}