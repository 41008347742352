<template>
	<page-simple>
		<div class="mb-3 mb-md-5 mt-6">
			<div class="container">
				<div class="page-head">
					<h1 class="mb-2"><strong>Online Privacy Policy</strong></h1>
					<p class="mb-6"><strong>LAST UPDATED APRIL 2020</strong></p>
				</div>
				
				<div class="card p-6">
				  <p class="" >By accessing or using this Janover Ventures, LLC website, (the "Website"), you agree to the terms of this Privacy Policy, as described below. If you do not agree to these terms, please do not access or use the Website. Janover Ventures reserves the right to change this Privacy Policy from time to time at its sole discretion. Your use of the Website will be subject to the most current version of this Privacy Policy at the time of such use, and to the Website's Terms of Use.</p>
				  <p class="" ><strong>Agreement to this Policy</strong>: By using this Website you consent to this Privacy Policy, including your consent to our use and disclosure of information about you in the manner described herein. If you do not agree with any of its terms, please do not use this Website or submit any personal information.</p>
				  <p class="" >We take our ethical responsibilities and your privacy extremely seriously. We have a strong commitment both to the ethical handling of your information and to providing excellent service to all our customers and visitors to this Website. This Privacy Policy discloses how we collect, protect, use, and share information gathered about you on our Website. We hope that this disclosure will help increase your confidence in our Website specifically and enhance your experience on the Internet generally. Therefore, in compliance with industry standards and regulations typically enforced by the federal and state governments, we abide the following privacy policy.</p>
				  <h3><strong>BROWSING</strong></h3>
				  <p class="" >This Website does not collect personally identifiable information from your computer when you browse this Website and request pages from our servers. This means unless you voluntarily and knowingly provide us with your personally identifiable information, we will not know your name, your email address, or any other personally identifiable information about you. We may, however, use IP addresses, browser types, and access times to analyze trends, administer the site, improve site performance and gather broad demographic information for aggregate use. When you request a page from our Website, our servers may log the information provided in the HTTP request header including the IP number, the time of the request, the URL of your request, and other information that is provided in the HTTP header. We collect the HTTP request header information in order to make our Website function correctly and provide you with the functionality you see on the Website. We also use this information to better understand how visitors use our website and how we can better fine-tune it, its contents, and functionality to best meet your needs.</p>
				  <h3><strong>COLLECTION OF INFORMATION</strong></h3>
				  <p class="" >When you engage in certain activities on the Website, the Website may ask you to provide certain information about yourself by filling-out and submitting an online form. It is completely optional for you to engage in these activities. If you elect to engage in these activities, however, the Website may ask that you provide personal information, such as your first and last name, mailing address (including zip code), e-mail address, company name, telephone and facsimile numbers, and other personal identifying information. We may also request your loan amount and other financial information. Depending upon the activity, some of the information that you will be asked to provide is identified as mandatory and some as voluntary. If you do not provide the mandatory data with respect to a particular activity, you will not be able to engage in that activity.</p>
				  <p class="" >When you submit information to Janover Ventures via the Website, you understand and agree that the information may be transferred across national boundaries and may be stored and processed in any of the countries in which Janover Ventures and its affiliates maintain offices, including without limitation, the United States. You also acknowledge that, in certain countries or with respect to certain activities, the collection, transferring, storage, and processing of your information may be undertaken by trusted vendors of Janover Ventures.&nbsp;</p>
				  <p class="" >The servers that Janover Ventures uses to operate the Website may collect domain names, IP addresses and e-mail addresses of visitors. This information may be aggregated to measure the number of visits, average time spent on the Website, pages viewed, and similar information. Janover Ventures may use this information to measure the use of the Website and to improve the content of the Website or other Websites operated by Janover Ventures and/or its affiliates.&nbsp;</p>
				  <h3><strong>HOW INFORMATION IS USED</strong></h3>
				  <p class="" >Janover Ventures collects information to record and support your participation in the activities you select and may share with third parties that may be able to provide certain loans products and services. The information that you provide may also be used as part of Janover Ventures effort to keep you informed about new products and services, product and services upgrades and modifications, special offers, and other products and services of Janover Ventures and selected third parties. Janover Ventures reserves the right to compile and use aggregate data, and also to provide such aggregate data to third parties for statistical analysis. Such data will not be linked to any particular individuals or companies. Janover Ventures also reserves the right to make any disclosures required by law or any governmental agency. &nbsp;</p>
				  <p class="" >We may also use the information we collect for other reasons including, but not limited to:</p>
				  <ul data-rte-list="default">
				    <li>
				      <p class="" >learning about customer interest in the services we offer and providing them with information</p>
				    </li>
				    <li>
				      <p class="" >enrolling customers who desire our services</p>
				    </li>
				    <li>
				      <p class="" >opening customer files &nbsp;</p>
				    </li>
				    <li>
				      <p class="" >providing customer service</p>
				    </li>
				    <li>
				      <p class="" >learning how to improve our products or services</p>
				    </li>
				    <li>
				      <p class="" >sharing your information and providing opportunities for our affiliates and other companies to inform you about the products or services (including lending) they offer that may interest you &nbsp;</p>
				    </li>
				    <li>
				      <p class="" >sharing aggregated statistical data with our business partners or for public relations. For example, we may disclose that a specific percentage of our users are located in a particular state or region.</p>
				    </li>
				  </ul>
				  <p class="" >Aside from the ways mentioned above, we may also use your information for other legally permissible purposes. Examples of some additional legally permissible purposes are:</p>
				  <p class="" ><em>Email</em>: We may use your information to provide email marketing materials. Such email marketing may feature the goods and services of third parties, and may be unrelated to the products or services offered or promoted on the Website.&nbsp;&nbsp;</p>
				  <p class="" ><em>Direct Mail</em>: We may use your information to advertise, directly or indirectly to using postal mail.</p>
				  <p class="" ><em>Profiles of Users</em>: We may use your information to make a profile of users of the Website.</p>
				  <p class="" ><em>Storage of User Information</em>: We may store your information in a database on secure computers.</p>
				  <p class="" ><em>Sale, License or Transfer to Third Parties</em>: With your permission, we may sell, license or transfer your information to third parties for the purposes of sending you offers of goods or services via email, postal mail and/or telemarketing. This may include the transfer of your name, address, phone number, email address, IP Address, date/time you visited the Site, and evidence that you have accepted any terms on the Site (including the Terms of Use or this Privacy Policy).</p>
				  <p class="" ><em>Legal Process</em>: We may disclose your information to respond to subpoenas, court orders and other legal processes.</p>
				  <p class="" ><em>Investigation:&nbsp;</em>We may use your information if we believe it is necessary in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, or violations of our terms of use.</p>
				  <p class="" ><em>Summary Data</em>: We may sell or transfer non-individualized information, such as summary or aggregated anonymous information about users of our websites as a whole or as sub-groups of users.</p>
				  <h3><strong>USE OF COOKIES</strong></h3>
				  <p class="" >When you visit the Website you can browse the Website anonymously and access important information without revealing your identity. To improve the Website, we may use "cookies" to track your visit. A cookie is a small amount of data that is transferred to your browser by a website server and can only be read by the server that gave it to you. It functions as your identification card, recording your passwords, purchases, and preferences. A cookie may be used to provide to you tailored information.</p>
				  <p class="" >Most browsers are initially set to accept cookies. You can set your browser to notify you when you receive a cookie, giving you the chance to decide whether to accept it. For some web pages that require an authorization, cookies are not optional. Users choosing not to accept cookies will probably not be able to access those pages.&nbsp;</p>
				  <p class="" >While the Website may use cookies to track your visit to the Website and the Website servers automatically log the IP/Internet address of your computer, this information does not identify you personally and you remain anonymous unless you have otherwise provided Janover Ventures with personal information.</p>
				  <h3><strong>LINKS TO OTHER WEBSITES</strong></h3>
				  <p class="" ><strong>This Website contains links to other sites such as affiliates as well as other third parties that are not owned or controlled by us. Please be aware that this Privacy Policy only addresses the use and disclosure of your information collected on this Website.</strong></p>
				  <p class="" ><strong>Janover Ventures and the Website connect users with third parties whose respective privacy policies govern your interactions with them. We encourage you to be aware when you leave our site and to read the applicable privacy policies and terms of conditions of each and every website that collects personally identifiable information.</strong></p>
				  <h3><strong>CHOICE/OPT-OUT</strong></h3>
				  <p class="" >We provide you the opportunity to "opt-out" of having your personally identifiable information used for certain purposes.&nbsp;&nbsp;</p>
				  <p class="" >If you no longer wish to receive emails from us and/or promotional communications, you may opt-out of receiving them by following the instructions included in each communication or by emailing or calling us per the information contained on our Website.</p>
				  <p class="" >Customer Service Contact Information:</p>
				  <p class="" ><strong>Janover Ventures, LLC</strong><br>7601 N Federal Hwy Ste B-140<br>Boca Raton FL 33487<br><a href="mailto:hello@janover.ventures">hello@janover.ventures</a></p>
				  <h3 ><strong>How does our site handle do not track signals?&nbsp;</strong></h3>
				  <p class="" >We honor do not track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.&nbsp;</p>
				  <h3 ><strong>Does our site allow third-party behavioral tracking?&nbsp;</strong></h3>
				  <p class="" >It's also important to note that we allow third-party behavioral tracking.</p>
				  <h3 ><strong>Protecting Children's Privacy Online</strong></h3>
				  <p class="" >The Site is not directed to individuals under the age of thirteen (13), and we request that these individuals do not provide Personal Information through the Site. We do not knowingly collect information from children under 13 without parental consent. Visit the Federal Trade Commission website for more information about the Children's Online Privacy Protection Act (COPPA). If you believe that we have received information from a child or other person who is not of a majority age in their relevant jurisdiction, please contact us at our email or physical mailing address listed above and we will take all reasonable efforts to remove the information.</p>
				  <h3><strong>ANALYTICS&nbsp;</strong></h3>
				  <p class="" >Janover Ventures may utilize third-party firms to monitor and analyze the use of our Website.</p>
				  <p class="" >Janover Ventures utilizes Google cookie tracking (part of Google Analytics Advertising Features) to collect data about user demographics and interests and for Google Analytics Advertising. Google cookie tracking is also used to improve targeting for advertising campaigns across Google's Display and Search Remarketing Ad Network and for Enhanced Link Attribution to better track links on our Website. The data that is collected in this way is not personally identifiable. However, visitors might observe certain ads on other websites because we contract with Google and other similar firms to target our advertising based on information they or we have collected. This includes data that was collected via automated methods (including web beacons and cookies). Web beacons are generally small, transparent digital image files that are used to determine if a user has accessed a specific webpage.</p>
				  <h3><strong>BEHAVIORAL REMARKETING</strong></h3>
				  <p class="" >Janover Ventures utilizes remarketing services to place ads on third-party websites. These ads may be targeted to you after you visit our Website. We may work with third-party firms that utilize cookies to help create optimized ads based on your previous visits to our Website. Information about how to customize or opt-out of these services can be found below.</p>
				  <h3><strong>GOOGLE ADWORDS</strong></h3>
				  <p class="" >Specifically, Janover Ventures may utilize Google Inc.'s Google AdWords remarketing service. To opt-out of this and other Google Analytics Advertising Features, you can install the Google Analytics opt-out browser add-on. This add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about your website visits. You may download it here:<a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>
				  <p class="" >Alternatively, you can edit your specific Google Analytics Advertising preferences here:&nbsp;<a href="https://adssettings.google.com/authenticated?hl=en">https://adssettings.google.com/authenticated?hl=en</a>.</p>
				  <p class="" >You can also opt-out of other online advertising tracking systems here:&nbsp;<a href="http://optout.networkadvertising.org/?c=1">http://optout.networkadvertising.org/?c=1</a>&nbsp;or here&nbsp;<a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.</p>
				  <p class="" >For a more in-depth look at Google's privacy practices, you can visit the Google Privacy &amp; Terms web page here:&nbsp;<a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a>.</p>
				  <h3><strong>FACEBOOK</strong></h3>
				  <p class="" >Janover Ventures may also utilize Facebook Inc.'s remarketing services to target ads to our visitors. Facebook has provided a short guide to its interest-based advertising here:&nbsp;<a href="https://www.facebook.com/help/164968693837950">https://www.facebook.com/help/164968693837950</a>.</p>
				  <p class="" >Additionally, users can opt-out of Facebook's interest-based advertising by following these instructions:&nbsp;<a href="https://www.facebook.com/help/568137493302217">https://www.facebook.com/help/568137493302217</a>.</p>
				  <p class="" >Facebook, Inc. adheres to the Self-Regulatory Principles for Online Behavioral Advertising created by the Digital Advertising Alliance. Visitors can opt-out of online behavioral ads from Facebook, Inc. and other participating companies via the Digital Advertising Alliance in the USA:&nbsp;<a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>, the Digital Advertising Alliance of Canada in Canada:&nbsp;<a href="http://youradchoices.ca/">http://youradchoices.ca/</a>, or the European Interactive Digital Advertising Alliance in Europe:&nbsp;<a href="http://www.youronlinechoices.eu/">http://www.youronlinechoices.eu/</a>. Users also may be able to opt-out of behavioral ads on mobile devices by changing their mobile device settings.</p>
				  <p class="" >To learn more about Facebook, Inc.'s privacy practices, you can read Facebook's Data Policy here:&nbsp;<a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a>.</p>
				  <h3><strong>TABOOLA</strong></h3>
				  <p class="" >Data is collected and stored for optimization purposes on this service using technologies from Taboola. Taboola's tags and pixel on our websites collect information about page visits and actions (click, conversion) tied to a hashed Taboola User ID on our pages. Specifically, the pixel collects event from our website (including initial and subsequent page visits, conversion data, and the associated hashed Taboola User ID read from the cookie) and information about the user's browser read from the user agent that includes operating system, browser type and version. You may opt-out from having Taboola collect your information by visiting the Taboola Opt-out page at&nbsp;<a href="https://www.taboola.com/privacy-policy#user-choices-and-optout">https://www.taboola.com/privacy-policy#user-choices-and-optout</a>.</p>
				  <h3><strong>OUTBRAIN</strong></h3>
				  <p class="" >We integrated the 'Custom Audience' Pixel from Outbrain into our site. Via this pixel there will be a direct connection between your browser and the Outbrain server. By this connection Outbrain is enabled to access certain browser information from your device. This enables us to do targeted marketing activities (for example banner ads) which will be shown to you on other websites which integrated the Outbrain Widget. For more information please see the Outbrain Privacy Policy&nbsp;<a href="https://www.outbrain.com/legal/privacy#advertising_behavioral_targeting">https://www.outbrain.com/legal/privacy#advertising_behavioral_targeting</a>&nbsp;where you can also opt-out from under section 2&nbsp;<a href="https://www.outbrain.com/legal/privacy#advertising_behavioral_targeting">https://www.outbrain.com/legal/privacy#advertising_behavioral_targeting</a>.</p>
				  <p class="" >&nbsp;</p>
				  <p class="" ><strong>Please print and retain a copy of this Privacy Policy for your records.</strong></p>
				  <p class="" >&nbsp;</p>
				</div>
			</div>
		</div>
	</page-simple>
</template>



<script>
	
	import PageSimple from '@/components/PageSimple'

	export default {
		components: {
			PageSimple
		}
	}

</script>



<style scoped>
	p+h3 {
		margin-top: 1em;
	}
</style>