<template>
  <div class="form-group">
    <label class="form-check">
      <input 
        @input="updateValue($event.target.checked)"
        type="checkbox" 
        :id="id"
        :class="{ 'is-invalid': invalid }"
        class="form-check-input">
      <span class="form-check-label" v-html="label"></span>
    </label>
  </div>
</template>

<script>
  
  export default {
    name: 'FormCheckbox',

    props: {
      id: String,
      value: Boolean,
      label: String,
      type: String,
      invalid: Boolean
    },

    methods: {
      updateValue (value) {
        this.$emit('input', value)
      }
    }
  }

</script>

<style lang="scss" scoped>
  .form-check {
    cursor: pointer;
  }
</style>