<template>
  <form class="card" @submit.prevent="handleFormSubmit" novalidate>
    <div class="card-body">
      <h3 class="card-title">
        Form W-9 
        <b class="text-green" v-if="hasFile">(recieved)</b>
        <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank" v-else class="text-azure ml-2">[W-9 template]</a>
      </h3>

      <notification
        type="danger"
        :message="alert"
        v-if="alert"
      />

      <form-file 
        v-if="!hasFile || showForm"
        v-model="file"
        placeholder="Choose file"
        :invalid="showAlerts && !fileIsValid"
      />
    </div>
    
    <div class="card-footer text-right">
      <button
        v-if="!hasFile || showForm"
        type="submit"
        :disabled="isLoading"
        key="submit-button"
        class="btn btn-primary">
        Upload file
      </button>

      <template v-else>
        <button
          type="button"
          class="btn btn-primary"
          key="toggle-button"
          @click="toggleForm"
        >
          Update
        </button>
        <button
          type="button"
          class="btn btn-danger ml-4"
          key="delete-button"
          @click="deleteDocument"
        >
          Delete
        </button>
      </template>
    </div>
  </form>
</template>

<script>

  import FormFile from '@/components/FormFile'
  import Notification from '@/components/Notification'
  
  export default {
    name: 'ProfileTaxpayerForm',

    components: {
      FormFile,
      Notification
    },

    data () {
      return {
        file: null,
        isLoading: false,
        showAlerts: false,
        alert: null,
        showForm: false
      }
    },

    computed: {
      profile () {
        return this.$store.getters.profile
      },

      hasFile () {
        return (this.profile) ? this.profile.hasW9 : false
      },

      fileIsValid () {
        return !!this.file
      }
    },

    methods: {
      toggleForm () {
        this.showForm = true
      },

      async deleteDocument () {
        if (!this.isLoading) {
          try {
            await this.$store.dispatch('deleteW9')
            this.file = null
            this.showForm = true
            this.isLoading = false
          } catch(e) {
            console.log(e)
            this.alert = e.error || e
            this.isLoading = false
          }
        }
      },

      async handleFormSubmit () {
        const formData = new FormData()
    
        if (!this.isLoading) {
          this.showAlerts = false
          this.alert = ''

          if (this.fileIsValid) {
            this.isLoading = true

            formData.append('file', this.file)
            formData.append('postfix', 'w9')
            formData.append('private', true)

            try {
              await this.$store.dispatch('updateW9', formData)
              this.file = null
              this.showForm = false
              this.isLoading = false
            } catch(e) {
              console.log(e)
              this.alert = e.error || e
              this.isLoading = false
            }
          } else {
            this.showAlerts = true
          }
        }
      }
    }
  }

</script>
