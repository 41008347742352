<template>
  <div class="form-group">
    <div class="custom-file">
      <input
        type="file"
        class="custom-file-input"
        name="example-file-input-custom"
        :class="{ 'is-invalid': invalid }"
        @change="handleFileChange">
      <label class="custom-file-label">{{ displayedName }}</label>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'FormFile',

    props: {
      placeholder: String,
      invalid: Boolean
    },

    data () {
      return {
        file: null,
        beenChanged: false
      }
    },

    computed: {
      displayedName () {
        return (this.file) ? this.file.name : this.placeholder
      }
    },

    methods: {
      handleFileChange (e) {
        this.beenChanged = true
        this.file = (e.target.files[0]) ? e.target.files[0] : null
        this.$emit('input', this.file)
      }
    }
  }

</script>
