<template>
  <div id="app" class="page">
    <router-view v-if="isReady" />
    <div class="loader" v-else></div>
  </div>
</template>

<script>
  export default {
    name: 'App',

    computed: {
      isAuthenticated() {
        return this.$store.state.isAuthenticated
      }
    },

    data () {
      return {
        isReady: false
      }
    },

    methods: {
      getUrlParams() {
        const pairs = window.location.search.substring(1).split("&"),
              result = {};
        
        for (let i in pairs) {
          if (pairs[i] === "") continue
          let pair = pairs[i].split("=")
          result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
        }

        return result
      }
    },

    created () {
      window.urlParams = this.getUrlParams()

      this.$store.dispatch('init', window.location.pathname).then(result => {
        this.isReady = result
      })
    }
  }
</script>

<style>
  @import "https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css";
</style>

<style lang="scss" scoped>
  .loader {
    margin: 0 auto;
  }
</style>