<template>
  <div class="card">
    <div class="card-header align-items-center justify-content-between py-4" v-if="profile.firstName && profile.lastName">
      <div class="mr-4" style="max-width: calc(100% - 80px);">
        <span class="text-default">{{ profile.firstName }} {{ profile.lastName }}</span>
        <small class="text-muted d-block mt-1">{{ profile.bio || profile.company }}</small>
      </div>

      <avatar-box />
    </div>

    <div class="card-body">
      <p>
        <span class="form-label">Profile status</span>
        
        <span class="text-default" v-if="status == 'yellow'">
          <span class="status-icon bg-warning"></span> Update your personal details
        </span>
        <span class="text-default" v-if="status == 'green'">
          <span class="status-icon bg-success"></span> Active
        </span>
      </p>
    </div>
    
    <div class="card-body">
      <p>
        <span class="form-label">Company</span>
        <span class="text-default">{{ profile.company || 'Not listed' }}</span>
      </p>

      <template v-if="profile.categories.length > 0">
        <span class="form-label">Role</span>
        <div class="tags">
          <span class="tag" v-for="role in profile.categories">{{ role }}</span>
        </div>
      </template>
    </div>

    <div class="card-footer">
      <button class="btn btn-secondary" @click="logout">Log out</button>
    </div>
  </div>
</template>



<script>

  import AvatarBox from '@/components/ProfileAvatarBox'

  export default {
    name: 'ProfileMainInfo',

    components: {
      AvatarBox
    },

    computed: {
      profile () {
        return this.$store.getters.profile
      },

      profileIsReady () {
        const requiredFields = ['firstName', 'lastName', 'company', 'phoneNumber', 'bio']

        let hasEmptyFields = false

        requiredFields.forEach(field => {
          if (!this.profile[field]) hasEmptyFields = true
        })

        return !hasEmptyFields
      },

      status () {
        if (!this.profileIsReady || !this.profile.referralCode) return 'yellow'
        else return 'green'
      }
    },

    methods: {
      logout () {
        this.$store.dispatch('logout')
      }
    }
  }

</script>
