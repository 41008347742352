<template>
  <div class="form-group">
    <label class="form-label" :for="id">
      {{ label }}
      <router-link
        v-if="addon"
        :to="addon.link"
        class="float-right small"
      >
        {{ addon.label }}
      </router-link>
    </label>
    <input 
      :value="value"
      @input="updateValue($event.target.value)"
      :type="type" 
      :id="id" 
      :placeholder="placeholder" 
      :class="{ 'is-invalid': invalid }"
      :disabled="disabled"
      ref="field"
      class="form-control"
    >
  </div>
</template>

<script>

  import Inputmask from 'inputmask'
  
  export default {
    name: 'FormField',

    props: {
      id: String,
      value: String,
      label: String,
      type: String,
      placeholder: String,
      invalid: Boolean,
      addon: Object,
      disabled: Boolean,
      mask: String
    },

    data () {
      return {
        maskInstance: null
      }
    },

    methods: {
      updateValue (value) {
        this.$emit('input', value)
      }
    },

    mounted () {
      if (this.mask) {
        this.maskInstance = new Inputmask(this.mask)
        this.maskInstance.mask(this.$refs.field)
      }
    }
  }

</script>

<style lang="scss" scoped>
  
</style>