<template>
  <page-simple v-if="routeIsReady">
    <div class="my-3 my-md-5">
      <div class="container">
        <notification
          type="warning"
          message="You should set up your profile to start earning money!" 
          v-if="!profile.referralCode" />

        <div class="row">
          <div class="col-lg-4">
            <main-info />

            <password-update />
          </div>
              
          <div class="col-lg-8">
            <referral-link v-if="profile.referralCode" />

            <invitation />
            
            <details-update />

            <taxpayer-form />

            <revenue-share />
          </div>
        </div>
      </div>
    </div>
  </page-simple>
</template>



<script>

  import route from '@/mixins/route.js'
  
  import PageSimple from '@/components/PageSimple'

  import DetailsUpdate  from '@/components/ProfileDetailsUpdate'
  import MainInfo       from '@/components/ProfileMainInfo'
  import PasswordUpdate from '@/components/ProfilePasswordUpdate'
  import ReferralLink   from '@/components/ProfileReferralLink'
  import RevenueShare   from '@/components/ProfileRevenueShare'
  import TaxpayerForm   from '@/components/ProfileTaxpayerForm'
  import Invitation     from '@/components/ProfileInvitation'

  import Notification from '@/components/Notification'
	
	export default {
		name: 'Profile',

    mixins: [
      route
    ],

    computed: {
      profile () {
        return this.$store.getters.profile
      },

      avatarPlaceholder () {
        let placeholder = ''

        if (this.profile.firstName) placeholder += this.profile.firstName.split('')[0]
        if (this.profile.lastName) placeholder += this.profile.lastName.split('')[0]

        return placeholder
      },

      notifications () {
        return this.$store.state.notifications
      }
    },

    components: {
      PageSimple,
      DetailsUpdate,
      MainInfo,
      Notification,
      ReferralLink,
      RevenueShare,
      PasswordUpdate,
      TaxpayerForm,
      Invitation
    },

		methods: {
			logout () {
        this.$store.dispatch('logout')
      }
		}
	}

</script>
